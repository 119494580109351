/* @ts-ignore */
import UserAvatar from "react-user-avatar";
import { formatCurrency } from "../util/Helpers";
import PaymentForm from "./PaymentForm";
import { useDispatch } from "react-redux";
import { Dispatch } from "react";
import * as Actions from "../store/actions";
import { useNavigate } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";

const Page3 = ({ data, trans }: any) => {
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  console.log(data);

  return (
    <div className=" mx-auto  pb-40 w-[100%] xl:w-[80%] overflow-y-scroll hide-scrollbar sticky top-0 flex gap-x-12">
      <div className="w-full md:w-[70%] mx-auto md:mt-4   xl:w-4/6">
        <div className="w-full rounded-lg p-6 bg-white">
          <div className="flex justify-between">
            <div className="font-bold flex text-lg lg:text-2xl mb-5">
              Price Details
              <sup>
                <div className="font-light text-sm py-2">
                  All prices are in
                  <span className="font-bold text-slate-400">
                    {trans?.currency}
                  </span>
                </div>
              </sup>
            </div>
            <div
              onClick={() => {
                navigate("/flights");
                dispatch(Actions.setPage(0));
              }}
              className="text-orange-500 md:flex hidden cursor-pointer bg-orange-100 text-[10px] md:text-xs font-light px-2 rounded-xl w-fit h-6  space-x-1 items-center"
            >
              Change Flight
              <BiEditAlt />
            </div>
          </div>
          <div className="py-3 border-b-slate-200 border-b-[1px]">
            {" "}
            <div className="w-full text-xl font-medium">
              {`Ticket for flight from ${data?.data[0]?.departcity} (${data?.data[0]?.departairport}) - ${data?.data[0]?.arrivalcity} (${data?.data[0]?.arrivalairport})`}
            </div>
          </div>

          <div className="flex justify-between py-1">
            <div>Base Fee</div>
            <div className="font-semibold">
              {formatCurrency(trans.amount, data.data[0].currency)}
            </div>
          </div>
          <div className="flex justify-between py-1">
            <div>Addon Fee</div>
            <div className="font-semibold">
              {formatCurrency(trans.addon, data.data[0].currency)}
            </div>
          </div>
          <div className="flex justify-between py-1">
            <div>Total Fare</div>
            <div className="font-semibold">
              {formatCurrency(trans.total, data.data[0].currency)}
            </div>
          </div>
        </div>
        {/* <Header data={data} /> */}
        <PaymentForm data={data} trans={trans} />
        <div className="w-full p-1  mt-2 bg-white  rounded-lg"></div>
        <div className="w-full p-1  mt-2 bg-white  rounded-lg"></div>
      </div>
    </div>
  );
};
export default Page3;

import { createSlice } from "@reduxjs/toolkit";

interface AirportState {
  loading: boolean;
  autoFlights: any;
}

const initialState: AirportState = {
  loading: false,
  autoFlights: null,
};

const airportSlice = createSlice({
  name: "airport",
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true;
    },

    autoFlightsLoaded: (state, action) => {
      state.loading = false;
      state.autoFlights = action.payload;
    },
    failed: (state) => {
      state.loading = false;
    },
  },
});

export const { failed, loading, autoFlightsLoaded } = airportSlice.actions;

export default airportSlice.reducer;

import { useSelector } from "react-redux";
import Footer1 from "../Layout/Footer/Footer1";
import NavbarTransparent from "../Layout/Navbar2/NavbarTransparent";
import Banner from "./Banner";

const PrivacyPolicy = () => {
  const currency = useSelector((state: any) => state.app.currency);
  return (
    <div className="relative w-full">
      <NavbarTransparent />
      <Banner />

      <div
        className="flex flex-col   -mt-32 rounded-t-[32px]  bg-slate-100"
        style={{ position: "inherit" }}
      >
        <div className="z-[1] flex  -mt-28 inset-0 flex-col items-center justify-center">
          <div className="w-[100%] z-[1] md:w-[90%] lg:w-[75%] xl:w-2/3 mb-2 mt-2 pb-5  h-auto  items-center">
            <div className=" mt-6 items-start text-white font-bold flex text-3xl w-full px-3">
              Privacy Policy
            </div>
          </div>
        </div>

        <div className="flex w-[100%] md:w-[90%] lg:w-[75%] xl:w-2/3 m-auto mt-2 md:mt-10">
          <div className="container mx-auto my-2 p-6 bg-white shadow-lg rounded-lg text-justify">
            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              1.Introduction
            </h3>
            <p className="mb-2">
              StaviGo ("StaviGo", "we", "us", "our") is committed to protecting
              the privacy of your personal information. This Privacy Policy
              explains how we collect, use, disclose, and store your personal
              information when you use the StaviGo travel booking platform
              ("Platform").
            </p>

            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              2. Information We Collect
            </h3>
            <p className="mb-2">
              We collect several types of information when you use the Platform:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Information you provide: This includes information you enter
                when you create an account, make a booking, contact us, or
                participate in surveys or promotions.
              </li>
              <li>
                Information collected automatically: When you use the Platform,
                we automatically collect certain information about your device
                and browsing activity.
              </li>
            </ul>
            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              3. How We Use Your Information
            </h3>
            <p className="mb-2">We use your personal information to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Provide and improve the Platform and travel booking services.
              </li>
              <li>
                Process your travel bookings and communicate with you about your
                trip.
              </li>
              <li>Personalize your experience on the Platform.</li>
              <li>Analyze the use of the Platform and improve our services.</li>
              <li>Comply with legal and regulatory requirements.</li>
            </ul>
            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              4. Sharing Your Information
            </h3>
            <p className="mb-2">We may share your personal information with:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Third-party service providers who help us operate the Platform
                and provide travel booking services.
              </li>
              <li>
                Travel suppliers, such as airlines, hotels, and car rental
                companies, to fulfill your travel bookings.
              </li>
              <li>
                We will not share your personal information with any third party
                for marketing purposes without your consent.
              </li>
              <li>
                We may disclose your personal information if required to do so
                by law.
              </li>
            </ul>
            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              5. Data Retention
            </h3>
            <p className="mb-2">
              We will retain your personal information for as long as necessary
              to fulfill the purposes described in this Privacy Policy, unless a
              longer retention period is required by law.
            </p>
            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              6. Your Choices
            </h3>
            <p className="mb-2">You have the right to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Access and update your account information.</li>
              <li>Opt-out of receiving marketing communications from us.</li>
              <li>
                Request that we delete your personal information (subject to
                legal and regulatory requirements).
              </li>
            </ul>
            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              7. Security
            </h3>
            <p className="mb-2">
              We take reasonable steps to protect your personal information from
              unauthorized access, disclosure, alteration, or destruction.
            </p>
            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              8. Children's Privacy
            </h3>
            <p className="mb-2">
              The Platform is not intended for children under the age of 18. We
              do not knowingly collect personal information from children under
              18.
            </p>
            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              9. International Transfers
            </h3>
            <p className="mb-2">
              StaviGo operates internationally. Your personal information may be
              transferred to and processed in countries other than your own,
              which may have different data protection laws. By using the
              Platform, you consent to the transfer of your personal information
              to these countries.
            </p>
            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              10. Compliance with Applicable Laws
            </h3>
            <p className="mb-2">
              We strive to comply with all applicable data privacy laws and
              regulations.
            </p>
            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              11. Changes to this Privacy Policy
            </h3>
            <p className="mb-2">
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the revised Privacy Policy on
              the Platform.
            </p>
            <h3 className="text-lg font-semibold mt-6 mb-4 text-[#000046]">
              12. Contact Us
            </h3>
            <p className="mb-2">
              If you have any questions about this Privacy Policy, please
              contact us at support@stavigo.com or via our contact numbers at{" "}
              <a
                href={`tel:${
                  currency?.country === "NG"
                    ? "+234 (201) 350-3000"
                    : "+1 (888) 665-3405"
                }`}
              >
                {currency?.country === "NG"
                  ? "+234 (201) 350-3000"
                  : "+1 (888) 665-3405"}
              </a>
            </p>
            <p className="mb-2">Effective Date: 20th July, 2024</p>
          </div>
        </div>
        <Footer1 />
      </div>
    </div>
  );
};
export default PrivacyPolicy;

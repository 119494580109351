import { Dispatch, useState } from "react";
import BasicInputSolid from "../../Components/Inputs/BasicInputSolid";

import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions";
import { jwtDecode } from "jwt-decode";
import { useModal } from "../../Sliders/Modal/ModalContext";
//import { auth, provider, signInWithPopup } from "../../firebaseConfig";

const First = ({ currPage, setCurrPage, modalId }: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { hideModal } = useModal();
  const loading = useSelector((state: any) => state.client.loading);
  const isAuth = useSelector((state: any) => state.client.isAuth);

  const [email, setEmail] = useState("");
  const handleLoginSuccess = (tokenResponse: any) => {
    console.log("Token Response:", tokenResponse);
    const userInfo = tokenResponse.access_token
      ? {
          ...tokenResponse,
          provider: "google",
        }
      : {
          credential: tokenResponse.credential,
          provider: "google",
          ...jwtDecode(tokenResponse.credential), // Decoding JWT to get user data
        };

    dispatch(
      Actions.joinSocialClient(userInfo, successCallback, errorCallback)
    );
  };
  const successCallback = (data: any) => {
    if (!data?.client.email || !data?.client.fullname) {
      setCurrPage(3);
    } else {
      console.log("sad");
      setCurrPage(1);
      hideModal(modalId);
      //  setIsOpen(false);
    }
  };

  const errorCallback = () => {
    // Handle error
  };
  const login = useGoogleLogin({
    onSuccess: handleLoginSuccess,
    onError: () => console.log("Login Failed with OAuth Popup"),
  });

  /*   const handleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const token = result.user.getIdToken();
        console.log("Token: ", token);
      })
      .catch((error) => {
        console.error("Error during sign-in: ", error);
      });
  }; */

  useGoogleOneTapLogin({
    disabled: isAuth,
    onSuccess: handleLoginSuccess,
    onError: () => console.log("One Tap Login Failed"),
  });
  return (
    <div className="px-2 flex flex-col justify-center h-full">
      <div className="space-y-1 pt-10 pb-8 text-gray-800">
        <div className="font-bold text-lg">Get Started</div>
        <div className="font-light text-sm">
          Login with your email or use the social media login{" "}
        </div>
      </div>
      <div>
        <BasicInputSolid
          name="Email address"
          placeholder="Email address"
          value={email}
          type="email"
          onChange={(e: any) => {
            setEmail(e.target.value);
          }}
        />
        <div className="my-5">
          <button
            type="button"
            onClick={() => {
              dispatch(
                Actions.joinClient(
                  { email },
                  () => {
                    setCurrPage(currPage + 1);
                  },
                  () => {}
                )
              );
              // setIsOpen(!isOpen);
            }}
            className=" w-full bg-[#1c1a3a] justify-center text-base items-center gap-2 flex text-[#fff] font-bold px-4 py-3 rounded hover:bg-[#1c1a3a] focus:outline-none focus:bg-[#1c1a3a]"
          >
            {loading ? "Loading..." : "Sign In"}
          </button>
        </div>
      </div>
      <div className="text-gray-800 relative flex justify-center items-center w-2/3 py-5 m-auto">
        <div className="h-[0.04px] rounded-full w-full bg-[#1c1a3a]" />
        <div className="text-[#1c1a3a]  bg-white absolute text-sm font-semibold px-3">
          or
        </div>
      </div>
      <div className="m-auto w-full flex justify-center py-5">
        <div
          className="border-[1px] rounded-full p-2 w-fit hover:shadow-md"
          onClick={() => {
            /* handleSignIn() */
            login();
          }}
        >
          <img src="/google_single.png" width={30} />
        </div>
      </div>
    </div>
  );
};
export default First;

import { BsFillCreditCard2FrontFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import * as Actions from "../store/actions";
import FullModal from "../Components/Modal/FullModal";
import { Dispatch } from "react";
import StripeForm from "../FlightFinalOffer/StripeForm";

const LocalGateway = ({ isOpen, stripePromise, trans }: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const page = useSelector((state: any) => state.order.page);

  const appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret: trans?.vendorRef,
    /* @ts-ignore */
    appearance,
  };
  return (
    <FullModal isOpen={isOpen}>
      <div className="md:w-2/6 xl:w-2/4 px-2 md:px-0 py-6 w-[95%] bg-white mx-auto rounded-lg">
        <div className="flex space-x-3 mx-auto justify-center">
          <div className="w-40 h-24 space-y-2  flex flex-col justify-center items-center shadow-lg rounded-lg">
            <div className="text-3xl text-orange-500">
              <BsFillCreditCard2FrontFill />
            </div>
          </div>
        </div>
        <div className="p-6">
          {trans.vendorRef && (
            <Elements options={options} stripe={stripePromise}>
              <StripeForm
                clientSecret={trans?.vendorRef}
                action={() => {
                  dispatch(Actions.setPage(page + 1));
                }}
              />
            </Elements>
          )}
        </div>
      </div>
    </FullModal>
  );
};
export default LocalGateway;

import { Dispatch, useEffect, useState } from "react";
import BasicInputSolid from "../../Components/Inputs/BasicInputSolid";

import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions";
import BasicPhoneInput from "../../Components/Inputs/BasicPhoneInput";
import { FaPhoneAlt } from "react-icons/fa";
import { errorMessage } from "../../util/Helpers";
import { useModal } from "../../Sliders/Modal/ModalContext";

const Third = ({ setCurrPage, modalId }: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { hideModal } = useModal();
  const loading = useSelector((state: any) => state.client.loading);
  const user = useSelector((state: any) => state.client.user);

  const [fullName, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => console.log(JSON.stringify(tokenResponse)),
  });
  /*  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      console.log(JSON.stringify(credentialResponse));
    },
    onError: () => {},
  }); */
  useEffect(() => {
    if (user) {
      setFullname(user.fullname);
      setPhone(user.phone);
    }
  }, [user]);
  return (
    <div className="px-2 flex flex-col justify-center h-full">
      <div className="space-y-1 pt-10 pb-8 text-gray-800">
        <div className="font-bold text-lg">Get Started</div>
        <div className="font-light text-sm">Update your profile</div>
      </div>
      <div className="space-y-3">
        <BasicPhoneInput
          value={phone}
          label={"Phone Number"}
          placeholder="Phone Number"
          onChange={(value: any) => {
            setPhone(value);
          }}
          icon={
            <FaPhoneAlt className="text-[#BDFF1C] absolute right-5 text-xl" />
          }
        />
        <BasicInputSolid
          name="Fullname"
          placeholder="Fullname"
          value={fullName}
          onChange={(e: any) => {
            setFullname(e.target.value);
          }}
        />
        <div className="my-5">
          <button
            type="button"
            onClick={() => {
              dispatch(
                Actions.updateClient(
                  { fullName, phone },
                  () => {
                    setCurrPage(1);
                    hideModal(modalId);
                  },
                  () => {
                    errorMessage("Error");
                  }
                )
              );
              // setIsOpen(!isOpen);
            }}
            className=" w-full bg-[#1c1a3a] justify-center text-base items-center gap-2 flex text-[#fff] font-bold px-4 py-3 rounded hover:bg-[#1c1a3a] focus:outline-none focus:bg-[#1c1a3a]"
          >
            {loading ? "Loading..." : "Update Profile"}
          </button>
        </div>
      </div>
      <div className="text-gray-800 relative flex justify-center items-center w-2/3 py-5 m-auto">
        <div className="h-[0.04px] rounded-full w-full bg-[#1c1a3a]" />
        <div className="text-[#1c1a3a]  bg-white absolute text-sm font-semibold px-3">
          or
        </div>
      </div>
      <div className="m-auto w-full flex justify-center py-5">
        <div
          className="border-[1px] rounded-full p-2 w-fit hover:shadow-md"
          onClick={() => login()}
        >
          <img src="/google_single.png" width={30} />
        </div>
      </div>
    </div>
  );
};
export default Third;

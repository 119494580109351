import { useState, useEffect } from "react";

const VerifyCoutdown = ({ initialSeconds = 300, action }: any) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval: any = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds: any) => seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      action();
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  useEffect(() => {
    setIsActive(true);
  }, []);
  useEffect(() => {
    setSeconds(initialSeconds);
  }, [initialSeconds]);

  function convertSeconds(totalSeconds: any) {
    const secondsPerMinute = 60;

    const minutes: any = Math.floor(totalSeconds / secondsPerMinute);
    const seconds: any = Math.round(totalSeconds % secondsPerMinute);

    return (
      <div className="flex space-x-1 ">
        <div>{minutes.toString().length === 1 ? `0${minutes}` : minutes}</div>:
        <div>{seconds.toString().length === 1 ? `0${seconds}` : seconds}</div>
      </div>
    );
  }
  return (
    <div className="text-[#9A9A9A] text-xs text-center ">
      {convertSeconds(seconds)}
    </div>
  );
};

export default VerifyCoutdown;

/* @ts-ignore */
import UserAvatar from "react-user-avatar";

import "./NavbarTransparent.css";
import { useNavigate } from "react-router-dom";
import Currency from "../Currency";

import { useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "../Avatar";
import LoginProc from "../../Auth/LoginProc";
import { GiHamburgerMenu } from "react-icons/gi";
import { useModal } from "../../Sliders/Modal/ModalContext";
import { FaUser } from "react-icons/fa";

const LeftComp = ({ navigate }: any) => {
  return (
    <div className="flex gap-4 w-1/2 items-center">
      <div
        className="font-bold text-3xl  cursor-pointer w-40 md:w-52"
        onClick={() => {
          navigate("/");
        }}
      >
        <img
          src="https://stavigo.s3.us-west-2.amazonaws.com/Stavigo+(3).png"
          className="w-full h-full"
        />
      </div>
      {/* <div className="md:flex hidden gap-4 w-full justify-start items-center mt-2">
        <div className="px-2 flex justify-center items-center cursor-pointer">
          <span className="font-medium text-base underline-animation">
            Stays
          </span>
        </div>
        <div
          className="px-2 md:flex hidden justify-center items-center cursor-pointer"
          onClick={() => {
            navigate("/flights");
          }}
        >
          <span className="font-medium text-base underline-animation">
            Flight
          </span>
        </div>

        <div className="px-2 flex justify-center items-center cursor-pointer">
          <span className="font-medium text-base underline-animation">
            Train
          </span>
        </div>
      </div> */}
      {/* <InputWithButton
        className=""
        placeholder="Destination, attraction, hotels..."
      /> */}
    </div>
  );
};
const RightComp = ({ currency }: any) => {
  return (
    <div className="hidden md:flex gap-4 w-1/2 justify-end items-center">
      <div className="hover:bg-[#3a3a3a40] hover:rounded px-2   justify-center items-center cursor-pointer">
        <span className="font-medium text-base">Help</span>
      </div>
      <div className="hover:bg-[#3a3a3a40] hover:rounded px-2   justify-center items-center cursor-pointer">
        <a
          className="no-underline text-white hover:text-white hover:no-underline focus:text-white focus:no-underline"
          href={
            currency && currency.country === "NG"
              ? "tel:+234 (201) 350-3000"
              : "tel:+1 (888) 665-3405"
          }
        >
          <span className="font-medium text-base">Support</span>
        </a>
      </div>
      <Currency />
      <Avatar />
    </div>
  );
};
const RightComp2 = ({ showModal, isAuth }: any) => {
  return (
    <div className="md:hidden flex space-x-2 w-1/2 justify-end items-center">
      {!isAuth && (
        <div
          onClick={() => {
            showModal("slideInModal");
          }}
        >
          <FaUser className="hover:bg-[#3a3a3a40] hover:rounded px-2 text-4xl  justify-center items-center cursor-pointer" />
        </div>
      )}

      <div
        onClick={() => {
          showModal("settingModal");
        }}
        className="hover:bg-[#3a3a3a40] hover:rounded px-2 text-3xl  justify-center items-center cursor-pointer"
      >
        <GiHamburgerMenu />
      </div>
    </div>
  );
};

const NavbarTransparent = () => {
  const navigate = useNavigate();
  const { showModal } = useModal();
  const isAuth = useSelector((state: any) => state.client.isAuth);

  const currency = useSelector((state: any) => state.app.currency);
  const [isOpen2, setIsOpen2] = useState(false);

  return (
    <div
      id="navigate"
      className="absolute z-[3]  py-4 px-2 md:px-6 w-full text-white"
    >
      {" "}
      <LoginProc isOpen={isOpen2} setIsOpen={setIsOpen2} />
      <div className="flex justify-between w-full items-center">
        <LeftComp navigate={navigate} />

        <RightComp currency={currency} />
        <RightComp2 showModal={showModal} isAuth={isAuth} />
      </div>{" "}
    </div>
  );
};
export default NavbarTransparent;

/* @ts-ignore */

import { Adsense } from "@ctrl/react-adsense";
import { AiOutlineYoutube } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";

function ErrorPage() {
  return (
    <div className="relative h-screen bg-gray-100">
      <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-lg w-full text-center">
        <div className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 z-[-1]">
          <div className="bg-white rounded-[90px] h-[125px] shadow-[5px_5px_0_0_#f3f3f3]"></div>
          <div className="bg-white rounded-[90px] h-[125px] scale-130 shadow-[5px_5px_0_0_#f3f3f3] relative z-10"></div>
          <div className="bg-white rounded-[90px] h-[125px] shadow-[5px_5px_0_0_#f3f3f3] relative z-[90]"></div>
        </div>
        <h1 className="font-quicksand text-[86px] uppercase font-bold mb-2 text-gray-900">
          oops!
        </h1>
        <h2 className="font-quicksand text-[26px] font-bold text-gray-900">
          An error occured
        </h2>
        <a
          href="/"
          className="font-quicksand text-sm uppercase bg-[#000046] inline-block py-3 px-6 rounded-md text-white font-bold mt-5"
        >
          go back
        </a>
        <div className="flex space-x-10 justify-center text-lg py-6">
          <a
            className="w-10 h-10 flex items-center justify-center text-white bg-gray-300 rounded transition duration-200 hover:text-[#000046]"
            href="https://www.facebook.com/profile.php?id=61561203905309&mibextid=ZbWKwL"
          >
            <FaFacebookF />
          </a>
          <a
            className="w-10 h-10 flex items-center justify-center text-white bg-gray-300 rounded transition duration-200 hover:text-[#000046]"
            href="https://www.tiktok.com/@stavigo?_t=8orlf2bpgo9&_r=1"
          >
            <FaTiktok />
          </a>
          <a
            className="w-10 h-10 flex items-center justify-center text-white bg-gray-300 rounded transition duration-200 hover:text-[#000046]"
            href="https://www.instagram.com/reel/C-jQVs8J7o1/?igsh=cjYyd2NkbnhhYTRq"
          >
            <FaInstagram />
          </a>
          <a
            className="w-10 h-10 flex items-center justify-center text-white bg-gray-300 rounded transition duration-200 hover:text-[#000046]"
            href="https://www.youtube.com/watch?v=QlpBIWy860E"
          >
            <AiOutlineYoutube />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;

import { createSlice } from "@reduxjs/toolkit";

interface UpsellState {
  loading: boolean;
  failed: any;
  trans: any;
  feature: any;
  ofcQuery: any;
}

const initialState: UpsellState = {
  loading: true,
  failed: null,
  trans: null,
  feature: null,
  ofcQuery: {
    airline: [],
    locale: [],
  },
};

const upsellSlice = createSlice({
  name: "hold",
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true;
      state.failed = null;
    },

    failed: (state, action) => {
      state.loading = false;
      state.failed = action.payload;
    },
    transLoaded: (state, action) => {
      state.loading = false;
      if (action.payload.feature) {
        state.feature = JSON.parse(action.payload.feature);
      }
      state.trans = action.payload.trans;
    },
    ofcQueried: (state, action) => {
      state.loading = false;
      state.ofcQuery[action.payload.query] = action.payload.res;
    },
  },
});

export const { failed, loading, transLoaded, ofcQueried } = upsellSlice.actions;

export default upsellSlice.reducer;

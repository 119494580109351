import { createSlice } from "@reduxjs/toolkit";
import { getDateRange } from "../../util/Helpers";

interface FormState {
  airport: any;
  tripType: any;
  flightType: any;
  dateRange: any;
  date: any;
  passenger: any;
  progress: any;
  passForm: any;
  nearbyairport: boolean;
  includedCarrier: any;
  refundable: boolean;
  defaultDate: any;
}

const initialState: FormState = {
  tripType: localStorage.getItem("tripType")
    ? localStorage.getItem("tripType")
    : "One-way",
  flightType: "economy",
  airport: localStorage.getItem("airport")
    ? /* @ts-ignore */
      JSON.parse(localStorage.getItem("airport"))
    : { leaving: null, going: null },

  dateRange: getDateRange(),
  date: { startDate: null, endDate: null, key: "selection" },
  defaultDate: { startDate: null, endDate: null, key: "selection" },
  passenger: {
    adult: 1,
    child: 0,
    infant: 0,
  },
  progress: 0,
  passForm: {},
  nearbyairport: true,
  refundable: false,
  includedCarrier: [],
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    airportSelected: (state, action) => {
      state.airport = {
        ...state.airport,
        [action.payload.key]: action.payload.value,
      };
      localStorage.setItem("airport", JSON.stringify(state.airport));
    },
    dateSet: (state, action) => {
      let x = action.payload;

      state.date = x;
      if (x.default) {
        state.defaultDate = action.payload;
      }
    },
    passengerSet: (state, action) => {
      state.passenger = {
        ...state.passenger,
        [action.payload.key]: action.payload.value,
      };
      localStorage.setItem("passenger", JSON.stringify(state.passenger));
    },
    tripTypeSelected: (state, action) => {
      state.tripType = action.payload;
      localStorage.setItem("tripType", state.tripType);
    },
    flightTypeSelected: (state, action) => {
      state.flightType = action.payload;
      localStorage.setItem("flightType", state.flightType);
    },
    progressSet: (state, action) => {
      state.progress = action.payload;
    },
    passformSet: (state, action) => {
      state.passForm = action.payload;
    },
    nearByAirportSet: (state) => {
      state.nearbyairport = !state.nearbyairport;
    },
    refundableSet: (state) => {
      state.refundable = !state.refundable;
    },
    includedCarrierSet: (state, action) => {
      state.includedCarrier = action.payload;
    },
  },
});

export const {
  dateSet,
  progressSet,
  passengerSet,
  passformSet,
  airportSelected,
  tripTypeSelected,
  nearByAirportSet,
  includedCarrierSet,
  flightTypeSelected,
  refundableSet,
} = formSlice.actions;

export default formSlice.reducer;

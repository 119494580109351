import Footer1 from "../Layout/Footer/Footer1";
import NavbarTransparent from "../Layout/Navbar2/NavbarTransparent";
import Banner from "./Banner";

const AboutUs = () => {
  return (
    <div className="relative w-full">
      <NavbarTransparent />
      <Banner />

      <div
        className="flex flex-col   -mt-32 rounded-t-[32px]  bg-slate-100"
        style={{ position: "inherit" }}
      >
        <div className="z-[1] flex  -mt-28 inset-0 flex-col items-center justify-center">
          <div className="w-[100%] z-[1] md:w-[90%] lg:w-[75%] xl:w-2/3 mb-2 mt-2 pb-5  h-auto  items-center">
            <div className=" mt-6 items-start text-white font-bold flex text-3xl w-full px-3">
              About Us
            </div>
          </div>
        </div>

        <div className="flex w-[100%] md:w-[90%] lg:w-[75%] xl:w-2/3 m-auto mt-2 md:mt-10">
          <div className="container mx-auto my-2 p-6 bg-white shadow-lg rounded-lg text-justify">
            {/*  <p className="mb-2">
              Welcome to Stavigo, the online travel agency dedicated to
              providing seamless travel experiences. These terms and conditions
              govern your use of the Stavigo platform and the services offered
              through it. By accessing or using Stavigo, you agree to comply
              with these terms and conditions. If you do not agree with any part
              of these terms, you may not use Stavigo.
            </p> */}
            <p className="mb-2">
              Stavigo.com is a comprehensive online platform that offers a wide
              range of travel resources for individuals looking to plan their
              next adventure. From booking flights and accommodations to
              discovering new destinations and activities, Stavigo.com has
              everything travelers need to make the most of their trip.
            </p>
            <p className="mb-2">
              One of the standout features of Stavigo.com is its user-friendly
              interface, which allows visitors to easily search for and compare
              travel options based on their preferences and budget. The site
              also provides detailed guides and recommendations for popular
              destinations around the world, making it easy for users to find
              inspiration for their next trip.
            </p>
          </div>
        </div>
        <Footer1 />
      </div>
    </div>
  );
};
export default AboutUs;

import { AiOutlineYoutube } from "react-icons/ai";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";

const Footer1 = () => {
  return (
    <div className="w-[100%] lg:w-2/3 m-auto ">
      {/*   <hr />
      <div className="grid grid-cols-1 md:grid-cols-2 justify-center xl:grid-cols-4  py-7">
        <div className="">
          <span className="text-sm font-bold mb-1 text-slate-500">
            Contact Us
          </span>
          <div className="flex flex-col gap-2">
            <a className="hover:underline text-xs">Customer Support</a>
            <a className="hover:underline text-xs">Service Guarantee</a>
            <a className="hover:underline text-xs">Website Feedback</a>
            <div className="grid grid-cols-4 w-1/2">
              <img src="/facebook.png" width={30} />
              <img src="/x.png" width={30} />
              <img src="/wechat.png" width={30} />
              <img src="/youtube.png" width={30} />
            </div>
          </div>
        </div>
        <div className="">
          <span className="text-sm font-bold mb-1 text-slate-500">About</span>
          <div className="flex flex-col gap-2">
            <a className="hover:underline text-xs">About Stavigo.com</a>
            <a className="hover:underline text-xs">News</a>
            <a className="hover:underline text-xs">Career</a>
            <a className="hover:underline text-xs">Terms & Condition</a>
            <a className="hover:underline text-xs">Privacy Statement</a>
            <a className="hover:underline text-xs">
              Do not sell my Personal Inforamtion
            </a>
            <a className="hover:underline text-xs">About Blacksilicon</a>
          </div>
        </div>
        <div className="">
          <span className="text-sm font-bold mb-1 text-slate-500">
            Other Services
          </span>
          <div className="flex flex-col gap-2">
            <a className="hover:underline text-xs">Investor Relations</a>
            <a className="hover:underline text-xs">Stavigo.com Rewards</a>
            <a className="hover:underline text-xs">Affiliate Program</a>
            <a className="hover:underline text-xs">List My Hotel</a>
            <a className="hover:underline text-xs">All Hotels</a>
            <a className="hover:underline text-xs">Become a Supplier</a>
            <a className="hover:underline text-xs">Security</a>
          </div>
        </div>
        <div className="">
          <div className="text-sm font-light mb-2 text-slate-400">
            Payment Method
          </div>
          <div className="flex flex-wrap gap-2">
            <img
              src="/visa.png"
              width={30}
              className="border-solid border-[1px] inline-block w-9 h-6 rounded-sm mr-2 mb-2 border-[#dadfe6]"
            />
            <img
              src="/master-card.png"
              width={30}
              className="border-solid border-[1px] inline-block w-9 h-6 rounded-sm mr-2 mb-2 border-[#dadfe6]"
            />
            <img
              src="/american-express.png"
              width={30}
              className="border-solid border-[1px] inline-block w-9 h-6 rounded-sm mr-2 mb-2 border-[#dadfe6]"
            />
            <img
              src="/paypal.png"
              width={30}
              className="border-solid border-[1px] inline-block w-9 h-6 rounded-sm mr-2 mb-2 border-[#dadfe6]"
            />
            <img
              src="/diners.png"
              width={30}
              className="border-solid border-[1px] inline-block w-9 h-6 rounded-sm mr-2 mb-2 border-[#dadfe6]"
            />
            <img
              src="/discover.png"
              width={30}
              className="border-solid border-[1px] inline-block w-9 h-6 rounded-sm mr-2 mb-2 border-[#dadfe6]"
            />
            <img
              src="/apple-pay.png"
              width={30}
              className="border-solid border-[1px] inline-block w-9 h-6 rounded-sm mr-2 mb-2 border-[#dadfe6]"
            />
            <img
              src="/google-pay.png"
              width={30}
              className="border-solid border-[1px] inline-block w-9 h-6 rounded-sm mr-2 mb-2 border-[#dadfe6]"
            />
          </div>
          <div className="mt-6">
            {" "}
            <div className="text-sm font-light mb-2 text-slate-400 ">
              Our partners
            </div>
            <div className="flex flex-wrap gap-2 items-center">
              <div className="mr-[25px]">
                <img src="/GOOGLE.png" width={100} />
              </div>
              <div className="mr-[25px]">
                <img src="/TRIPADVISOR.png" width={120} />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <hr />
      <div className="text-xs m-auto py-1 flex items-center space-x-2 justify-center">
        <a href="/about">About Stavigo</a>
        <div>|</div>
        <a href="/terms">Terms of Service</a>
        <div>|</div>
        <a href="/policy">Privacy Policy</a>
      </div>
      <hr />
      <div className="flex space-x-10 justify-center text-lg ">
        <a
          className="cursor-pointer text-[#000046]"
          href="https://www.facebook.com/profile.php?id=61561203905309&mibextid=ZbWKwL"
        >
          <FaFacebookF />
        </a>
        <a
          className="cursor-pointer text-[#000046]"
          href="https://www.tiktok.com/@stavigo?_t=8orlf2bpgo9&_r=1"
        >
          <FaTiktok />
        </a>
        <a
          className="cursor-pointer text-[#000046]"
          href="https://www.instagram.com/reel/C-jQVs8J7o1/?igsh=cjYyd2NkbnhhYTRq"
        >
          <FaInstagram />
        </a>
        <a
          className="cursor-pointer text-[#000046]"
          href="https://www.youtube.com/watch?v=QlpBIWy860E"
        >
          <AiOutlineYoutube />
        </a>
      </div>
      <hr />
      <div className="text-xs m-auto pt-4 pb-2 flex flex-col justify-center">
        <div className="text-center ">
          Copyright © 2024 <span className="text-[#000046]">Stavigo</span>
          <span className="text-orange-500">.</span>
          <span className="text-[#000046]">com</span>. All rights reserved
        </div>
        {/*  <div className="text-center ">Powered by Blacksillicon LLC</div> */}
      </div>
    </div>
  );
};
export default Footer1;

import {
  failed,
  loading,
  dealsLoaded,
  destinationLoaded,
  cheapFlightLoaded,
  isActiveSet,
  secondsSet,
  secondReset,
} from "./miscSlice";
import Axios from "axios";
import { cheapFlightsUrl, flightDealsUrl, loadDestinationsUrl } from "./apis";

export const loadFlightDeals = () => (dispatch: any) => {
  dispatch(loading());
  Axios.get(flightDealsUrl)
    .then((res) => {
      dispatch(dealsLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err.response.data));
    });
};

export const loadDestinations = () => (dispatch: any) => {
  dispatch(loading());
  Axios.get(loadDestinationsUrl)
    .then((res) => {
      dispatch(destinationLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err.response.data));
    });
};
export const searchCheapFlight = (data: any, city: any) => (dispatch: any) => {
  dispatch(loading());
  Axios.post(cheapFlightsUrl + `"?city=${city}`, data)
    .then((res) => {
      dispatch(cheapFlightLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err.response.data));
    });
};

export const setSeconds = () => (dispatch: any) => {
  dispatch(secondsSet());
};
export const setIsActive = () => (dispatch: any) => {
  dispatch(isActiveSet());
};
export const resetSeconds = () => (dispatch: any) => {
  dispatch(secondReset());
};

import React, { CSSProperties } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Dispatch, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const Flight = React.lazy(() => import("./Flight"));
//const Home = React.lazy(() => import("./Home"));
const FlightSearch = React.lazy(() => import("./FlightSearch"));
const FlightFinalOffer = React.lazy(() => import("./FlightFinalOffer"));
const Profile = React.lazy(() => import("./Profile"));
const Loyalty = React.lazy(() => import("./Loyalty"));
const ProfileMenu = React.lazy(() => import("./ProfileMenu"));
const Layout = React.lazy(() => import("./Layout"));
import * as Actions from "./store/actions";
//import { getDefaultDateRange } from "./util/Helpers";
import RiseLoader from "react-spinners/RiseLoader";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import AboutUs from "./AboutUs";
import { jwtDecode } from "jwt-decode";
import { /*  GoogleLogin, */ useGoogleOneTapLogin } from "@react-oauth/google";
import Payment from "./Payment";
import ErrorBoundary from "./ErrorBoundary";
import ErrorPage from "./ErrorPage";
//import { googleLogout } from "@react-oauth/google";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#000046",
};

function App() {
  const dispatch: Dispatch<any> = useDispatch();
  const isAuth = useSelector((state: any) => state.client.isAuth);
  const user = useSelector((state: any) => state.client.user);
  const seconds = useSelector((state: any) => state.misc.seconds);
  const isActive = useSelector((state: any) => state.misc.isActive);
  const info = useSelector((state: any) => state.app.info);
  const currency = useSelector((state: any) => state.app.currency);
  /*   const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(false); */

  useEffect(() => {
    let interval: any = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        dispatch(Actions.setSeconds());
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      window.location.replace("/flights");
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  useEffect(() => {
    if (localStorage.getItem("svToken") !== null) {
      dispatch(Actions.loadClient());
    }
    //dispatch(Actions.getUserPastSearches());
    dispatch(Actions.loadCountries());
    dispatch(Actions.loadFlightDeals());
    // dispatch(Actions.loadCity());
    dispatch(Actions.loadProvider());
    dispatch(Actions.loadAirport());
    dispatch(Actions.loadVendor());
    dispatch(Actions.loadDestinations());
    dispatch(Actions.getClientInfo());
  }, []);
  useEffect(() => {
    if (isAuth) {
      dispatch(Actions.loadHistory());
      if (user.email) {
        dispatch(Actions.loadPriceAlert());
        // dispatch(Actions.loadNotification());
      }
      dispatch(Actions.getFrequentTraveler(user.email));
    }
  }, [isAuth]);

  useEffect(() => {
    if (info && info.country_name && info.country_name !== "-") {
      dispatch(
        Actions.ofcQuery({
          queryType: "locale",
          departCountry: info.country_name,
          // departCountry: "Nigeria",
          //currency: "NGN",
          currency: currency?.code,
        })
      );
    }
  }, [info, currency]);

  const handleLoginSuccess = (tokenResponse: any) => {
    console.log("Token Response:", tokenResponse);
    const userInfo = tokenResponse.access_token
      ? {
          ...tokenResponse,
          provider: "google",
        }
      : {
          credential: tokenResponse.credential,
          provider: "google",
          ...jwtDecode(tokenResponse.credential), // Decoding JWT to get user data
        };

    dispatch(
      Actions.joinSocialClient(userInfo, successCallback, errorCallback)
    );
  };
  const successCallback = (data: any) => {
    if (!data?.client.email || !data?.client.fullname) {
    } else {
      //  setIsOpen(false);
    }
  };

  const errorCallback = () => {
    // Handle error
  };

  useGoogleOneTapLogin({
    disabled: localStorage.getItem("svToken") !== null,
    onSuccess: handleLoginSuccess,
    onError: () => console.log("One Tap Login Failed"),
    auto_select: true,
    use_fedcm_for_prompt: true,
    prompt_parent_id: "oneTapContainer", // ID of the element where you want the prompt to appear
    cancel_on_tap_outside: false,
    // hosted_domain:
    //prompt_mode: 'display',
  });

  /*   useEffect(() => {
    dispatch(
      Actions.setFlightDate({
        startDate: addDays(new Date(), 7),
        endDate: addDays(new Date(), 14),
        key: "selection",
      })
    );
  }, []); */
  //googleLogout();
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout>
          <Flight />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/flights",
      element: (
        <Layout>
          <Flight />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/final",
      element: (
        <Layout>
          <FlightFinalOffer />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/flights/results/:search",
      element: (
        <Layout>
          {" "}
          <FlightSearch />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/payment/:feature/:ref",
      element: (
        <Layout>
          <Payment />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/profile",
      element: (
        <Layout>
          <Profile />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/policy",
      element: (
        <Layout>
          <PrivacyPolicy />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/about",
      element: (
        <Layout>
          <AboutUs />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/terms",
      element: (
        <Layout>
          <Terms />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/my_loyalty",
      element: (
        <Layout>
          <Loyalty />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/profile_menu",
      element: (
        <Layout>
          <ProfileMenu />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: "/error",
      element: (
        <Layout>
          <ErrorPage />
        </Layout>
      ),
      errorElement: <ErrorPage />,
    },
  ]);
  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <div
            className="w-full items-center h-screen flex justify-center"
            key={0}
          >
            <RiseLoader
              color={"#000046"}
              loading={true}
              cssOverride={override}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        }
      >
        <RouterProvider router={router} />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;

import { createSlice } from "@reduxjs/toolkit";

interface NotificationState {
  loading: boolean;
  failed: any;
  notifications: any;
}

const initialState: NotificationState = {
  loading: false,
  failed: null,
  notifications: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    failed: (state, action) => {
      state.loading = false;
      state.failed = action.payload;
    },
    notificationLoaded: (state, action) => {
      state.loading = false;
      state.notifications = action.payload;
    },
    notificationRead: (state) => {
      state.loading = false;
    },
  },
});

export const { failed, loading, notificationLoaded, notificationRead } =
  notificationSlice.actions;

export default notificationSlice.reducer;

import { baseUrl } from "../airport/apis";

export const loadClientUrl = `${baseUrl}/client/`;
export const clientJoinUrl = `${baseUrl}/client/join`;
export const clientSocialJoinUrl = `${baseUrl}/client/join_auth`;
export const verifyClientUrl = `${baseUrl}/client/verify`;
export const updateClientUrl = `${baseUrl}/client/update`;

export const addPriceAlertUrl = `${baseUrl}/price-alert/create`;
export const loadPriceAlertUrl = `${baseUrl}/price-alert/all`;
export const unsubscribePriceAlertUrl = `${baseUrl}/price-alert/unsubscribe/`;

import { createSlice } from "@reduxjs/toolkit";

interface UpsellState {
  loading: boolean;
  currDetails: any;
  details: any;
  failed: any;
  offerprice: any;
  offer: any;
  fareRule: any;
}

const initialState: UpsellState = {
  loading: false,
  failed: null,
  currDetails: null,
  details: {},
  offerprice: null,
  offer: null,
  fareRule: null,
};

const upsellSlice = createSlice({
  name: "upsell",
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true;
      state.failed = null;
    },

    failed: (state, action) => {
      state.loading = false;
      state.failed = action.payload;
    },

    detailLoaded: (state, action) => {
      state.loading = false;
      state.currDetails = action.payload.data;
    },
    offerpriceLoaded: (state, action) => {
      state.loading = false;
      state.offerprice = action.payload.result;
    },
    offerSelected: (state, action) => {
      state.offer = action.payload;
      localStorage.setItem("offer", action.payload);
    },
    fareRuleLoaded: (state, action) => {
      state.loading = false;
      state.fareRule = action.payload;
    },
    upsellReset: (state) => {
      state.loading = false;
      state.failed = null;
      state.currDetails = null;
      state.details = {};
      state.offerprice = null;
      state.offer = null;
      state.fareRule = null;
    },
  },
});

export const {
  failed,
  loading,
  detailLoaded,
  offerpriceLoaded,
  offerSelected,
  fareRuleLoaded,
  upsellReset,
} = upsellSlice.actions;

export default upsellSlice.reducer;

import { useState } from "react";
import First from "./First";
import Second from "./Second";
import FullModal from "../../Components/Modal/FullModal";
import { ImCancelCircle } from "react-icons/im";
import Third from "./Third";
import { useModal } from "../../Sliders/Modal/ModalContext";

const LoginProc = ({ setIsOpen, modalId }: any) => {
  const [currPage, setCurrPage] = useState(1);
  const { modalStates, hideModal } = useModal();
  const isOpen = modalStates[modalId]?.isOpen;
  const getPage = () => {
    switch (currPage) {
      case 1:
        return (
          <First
            isOpen={isOpen}
            modalId={modalId}
            setCurrPage={setCurrPage}
            currPage={currPage}
          />
        );
      case 2:
        return (
          <Second
            isOpen={isOpen}
            setCurrPage={setCurrPage}
            currPage={currPage}
            modalId={modalId}
          />
        );
      case 3:
        return (
          <Third
            isOpen={isOpen}
            setCurrPage={setCurrPage}
            currPage={currPage}
            modalId={modalId}
          />
        );
      default:
        return (
          <First
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setCurrPage={setCurrPage}
            currPage={currPage}
          />
        );
    }
  };
  if (!isOpen) return null;
  return (
    <FullModal isOpen={isOpen}>
      <div className=" w-[90%] md:w-3/6 lg:w-[35%] xl:w-1/4 bg-white m-auto rounded-xl min-h-[450px] h-auto z-[2] p-3 relative">
        <div
          className="cursor-pointer absolute right-3 flex w-full justify-end text-[#1c1a3a] font-bold text-xl text-end hover:text-red-500"
          onClick={() => {
            hideModal(modalId);
          }}
        >
          <ImCancelCircle />
        </div>
        {getPage()}
      </div>
    </FullModal>
  );
};
export default LoginProc;

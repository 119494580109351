import Footer1 from "../Layout/Footer/Footer1";
import NavbarTransparent from "../Layout/Navbar2/NavbarTransparent";

/* @ts-ignore */
import UserAvatar from "react-user-avatar";

import { useDispatch, useSelector } from "react-redux";

import { CSSProperties, Dispatch, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Page4 from "./Page4";
import * as Actions from "../store/actions";

import "./scrollbar.css";
import Banner from "../FlightFinalOffer/Banner";
import Page3 from "./Page3";
import RiseLoader from "react-spinners/RiseLoader";
import { errorMessage } from "../util/Helpers";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#000046",
};
const Payment = () => {
  const navigate = useNavigate();
  const { feature, ref } = useParams();

  const dispatch: Dispatch<any> = useDispatch();
  const page = useSelector((state: any) => state.order.page);
  const loading = useSelector((state: any) => state.hold.loading);
  const trans = useSelector((state: any) => state.hold.trans);
  const feat = useSelector((state: any) => state.hold.feature);

  useEffect(() => {
    if (feature && ref) {
      dispatch(
        Actions.loadHoldDetails(
          { feature, reference: ref },
          (data: any) => {
            if (data.trans.status === "c") {
              dispatch(Actions.setPage(1));
            }
            if (data.feature) {
              dispatch(Actions.setIsActive());
            } else {
              errorMessage("Something is wrong with your link");
              navigate("/flights");
            }
          },
          () => {}
        )
      );
    }
  }, [feature, ref]);

  const pageSwitch = () => {
    switch (page) {
      case 0:
        return <Page3 data={feat} trans={trans} />;
      case 1:
        return <Page4 data={feat} trans={trans} />;
      default:
        return <Page3 data={feat} trans={trans} />;
    }
  };

  return loading ? (
    <div className="w-full items-center h-screen flex justify-center" key={0}>
      <RiseLoader
        color={"#000046"}
        loading={true}
        cssOverride={override}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  ) : (
    <div className="relative w-full max-h-screen overflow-hidden">
      <NavbarTransparent />
      <Banner />
      <div
        className="flex  flex-col relative md:-mt-28 max-h-screen pb-12 overflow-hidden -mt-36 rounded-t-[32px]  bg-slate-100"
        style={{ position: "inherit" }}
      >
        {feat && trans && pageSwitch()}

        <Footer1 />
      </div>
    </div>
  );
};
export default Payment;

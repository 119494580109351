import { createSlice } from "@reduxjs/toolkit";
import { sortHistory } from "../../util/Helpers";

interface OrderState {
  loading: boolean;
  page: any;
  booked: any;
  failed: any;
  vendors: any;
  intent: any;
  process: any;
  vendor: any;
  pIntent: any;
  history: any;
  oneHistory: any;
  historyDetail: any;
  intentFinal: any;
}

const initialState: OrderState = {
  loading: false,
  page: 0,
  booked: null,
  failed: null,
  vendors: null,
  vendor: null,
  intent: null,
  process: null,
  pIntent: null,
  history: null,
  oneHistory: null,
  historyDetail: null,
  intentFinal: null,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    vendorLoaded: (state, action) => {
      state.loading = false;
      state.vendors = action.payload;
      state.vendor = action.payload.length > 0 && action.payload[0];
    },
    gatewayIntent: (state, action) => {
      state.loading = false;
      state.intent = action.payload;
    },
    gatewayProcess: (state, action) => {
      state.loading = false;
      state.process = action.payload;
    },
    flightRequery: (state, action) => {
      state.loading = false;
      state.booked = action.payload;
    },
    pageSet: (state, action) => {
      state.page = action.payload;
    },
    vendorSelect: (state, action) => {
      state.vendor = action.payload;
    },
    paymentIntent: (state, action) => {
      state.loading = false;
      state.pIntent = action.payload;
    },
    intentFinal: (state, action) => {
      state.loading = false;
      state.intentFinal = action.payload;
    },
    historyLoaded: (state, action) => {
      state.loading = false;
      state.history = sortHistory(action.payload);
    },
    historySelected: (state, action) => {
      state.oneHistory = action.payload;
    },
    historyDetailsLoaded: (state, action) => {
      state.loading = false;
      state.historyDetail = action.payload;
    },
    failed: (state) => {
      state.loading = false;
    },
    orderReset: (state: any) => {
      state.loading = false;
      state.page = 0;
      state.booked = null;
      state.failed = null;
      state.intent = null;
      state.process = null;
      state.pIntent = null;
      state.history = null;
      state.oneHistory = null;
      state.historyDetail = null;
    },
    flightCancel: (state) => {
      state.loading = false;
    },
  },
});

export const {
  loading,
  failed,
  pageSet,
  vendorLoaded,
  vendorSelect,
  gatewayIntent,
  flightRequery,
  paymentIntent,
  historyLoaded,
  gatewayProcess,
  historySelected,
  historyDetailsLoaded,
  orderReset,
  flightCancel,
  intentFinal,
} = orderSlice.actions;

export default orderSlice.reducer;

import {
  loading,
  failed,
  userJoin,
  userUpdated,
  userverified,
  userLoaded,
  userSocialJoin,
  priceAlertCreated,
  logout,
  priceAlertLoaded,
  priceAlertRemoved,
} from "./clientSlice";
import Axios from "axios";
import {
  clientJoinUrl,
  loadClientUrl,
  updateClientUrl,
  verifyClientUrl,
  clientSocialJoinUrl,
  addPriceAlertUrl,
  loadPriceAlertUrl,
  unsubscribePriceAlertUrl,
} from "./apis";

export const loadClient = () => (dispatch: any) => {
  dispatch(loading());
  Axios.get(loadClientUrl, tokenConfig())
    .then((res) => {
      dispatch(userLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};

export const loadPriceAlert = () => (dispatch: any) => {
  dispatch(loading());
  Axios.get(loadPriceAlertUrl, tokenConfig())
    .then((res) => {
      dispatch(priceAlertLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};
export const joinClient =
  (body: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.post(clientJoinUrl, body)
      .then((res) => {
        dispatch(userJoin({ data: res.data, email: body.email }));
        func();
      })
      .catch((err) => {
        dispatch(failed(err));
        errFunc();
      });
  };

export const addPriceAlert =
  (body: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.post(addPriceAlertUrl, body, tokenConfig())
      .then((res) => {
        dispatch(priceAlertCreated(res.data));
        dispatch(loadPriceAlert());
        func();
      })
      .catch((err) => {
        dispatch(failed(err));
        errFunc();
      });
  };
export const removePriceAlert =
  (id: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.delete(unsubscribePriceAlertUrl + id, tokenConfig())
      .then((res) => {
        dispatch(priceAlertRemoved(res.data));
        dispatch(loadPriceAlert());
        func();
      })
      .catch((err) => {
        dispatch(failed(err));
        errFunc();
      });
  };
export const joinSocialClient =
  (body: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.post(clientSocialJoinUrl, body)
      .then((res) => {
        dispatch(userSocialJoin(res.data));
        func(res.data);
      })
      .catch((err) => {
        dispatch(failed(err));
        errFunc();
      });
  };
export const verifyClient =
  (body: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.post(verifyClientUrl, body)
      .then((res) => {
        dispatch(userverified(res.data));
        func(res.data);
      })
      .catch((err) => {
        dispatch(failed(err));
        errFunc();
      });
  };
export const updateClient =
  (body: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.post(updateClientUrl, body, tokenConfig())
      .then((res) => {
        dispatch(userUpdated(res.data));
        func(res.data);
      })
      .catch((err) => {
        dispatch(failed(err));
        errFunc();
      });
  };
export const logoutClient = () => (dispatch: any) => {
  dispatch(logout());
};
export const tokenConfig = () => {
  const token = localStorage.getItem("svToken");
  const config: any = {
    headers: {
      // "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

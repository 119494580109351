// @ts-nocheck
import React from "react";
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorType: null };
  }

  static getDerivedStateFromError(error: any) {
    // Check for specific error types
    if (error.response && error.response.status === 500) {
      // If it's a 500 error from an API response
      return { hasError: true, errorType: "500" };
    } else if (error instanceof SyntaxError) {
      // If it's a syntax error (though this is rare in runtime)
      return { hasError: true, errorType: "syntax" };
    }

    // Catch all other errors
    return { hasError: true, errorType: "general" };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      if (this.state.errorType === "500") {
        // Render a custom error page for 500 errors
        return <ErrorPage message="Server Error: 500" />;
      } else if (this.state.errorType === "syntax") {
        // Render a custom error page for syntax errors
        return <ErrorPage message="Syntax Error" />;
      } else {
        // Render a generic error page for other types of errors
        return <ErrorPage />;
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import { createSlice } from "@reduxjs/toolkit";

interface AppState {
  loading: boolean;
  currency: any;
  city: any;
  airport: any;
  providers: any;
  countries: any;
  info: any;
  airport_route: any;
  airlineSearch: any;
  airportInfo: any;
  pastSearches: any;
  frequentTraveler: any;
  dateOpen: boolean;
}

const initialState: AppState = {
  loading: false,
  city: null,
  providers: null,
  countries: null,
  currency: localStorage.getItem("currency")
    ? /* @ts-ignore */
      JSON.parse(localStorage.getItem("currency"))
    : {
        id: 1,
        code: "USD",
        name: "US Dollar(USD)",
        rate: 1,
        created: 1710330643000,
        country: "US",
      },
  airport: null,
  info: null,
  airportInfo: null,
  airport_route: null,
  airlineSearch: null,
  pastSearches: null,
  frequentTraveler: [],
  dateOpen: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    currencySelect: (state, action) => {
      state.currency = action.payload;
      localStorage.setItem("currency", JSON.stringify(action.payload));
    },
    cityLoaded: (state, action) => {
      state.loading = false;
      state.city = arrayToHashMap(action.payload);
    },
    countryLoaded: (state: any, action: any) => {
      state.loading = false;
      state.countries = action.payload;
    },
    airportLoaded: (state, action) => {
      state.loading = false;
      state.airport = action.payload;
    },
    providersLoaded: (state, action) => {
      state.loading = false;
      state.providers = formatProvider(action.payload);
    },
    failed: (state) => {
      state.loading = false;
    },
    airportRouteLoaded: (state, action) => {
      state.loading = false;
      state.airport_route = action.payload;
    },
    airlineSearchLoaded: (state, action) => {
      state.loading = false;
      state.airlineSearch = action.payload;
    },
    clientAirportLoaded: (state, action) => {
      state.loading = false;
      state.airportInfo = action.payload.length > 0 && action.payload[0];
    },
    pastSearchLoaded: (state, action) => {
      state.loading = false;
      state.pastSearches = action.payload;
    },
    frequentTravelerLoaded: (state, action) => {
      state.loading = false;
      state.frequentTraveler = action.payload;
    },
    clientCountryInfoLoaded: (state, action) => {
      state.loading = false;
      //   state.currency = action.payload;
      localStorage.setItem("currency", JSON.stringify(action.payload));
    },
    clientInfoLoaded: (state, action) => {
      state.loading = false;
      state.info = action.payload;
      let x = state.countries.filter(
        (x: any) => state.info.country_code === x.country
      );
      if (localStorage.getItem("currency") || state.currency) {
        state.currency =
          /* @ts-ignore */
          state.currency || JSON.parse(localStorage.getItem("currency"));
      } else {
        state.currency =
          x.length > 0
            ? x[0]
            : {
                id: 1,
                code: "USD",
                name: "US Dollar(USD)",
                rate: 1,
                created: 1710330643000,
                country: "US",
              };
      }
    },
    setDateOpen: (state, action) => {
      state.dateOpen = action.payload;
    },
  },
});
function arrayToHashMap(array: any) {
  const hashMap: any = {};

  array.forEach((item: any) => {
    const code = item.code;
    hashMap[code] = item;
  });

  return hashMap;
}
const formatProvider = (data: any) => {
  let sorted = {};
  for (const item of data) {
    sorted = { ...sorted, [item.ref]: item };
  }
  return sorted;
};

export const {
  loading,
  currencySelect,
  cityLoaded,
  failed,
  airportLoaded,
  providersLoaded,
  countryLoaded,
  clientInfoLoaded,
  airlineSearchLoaded,
  airportRouteLoaded,
  clientAirportLoaded,
  pastSearchLoaded,
  clientCountryInfoLoaded,
  frequentTravelerLoaded,
  setDateOpen,
} = appSlice.actions;

export default appSlice.reducer;

import PropTypes from "prop-types";
import classNames from "classnames";

const FullModal = ({ isOpen, children }: any) => {
  const modalClass = classNames(
    "fixed z-[2]  inset-0 overflow-y-auto backdrop-filter backdrop-blur-sm ",
    {
      hidden: !isOpen,
      "bg-gray-800 bg-opacity-75": isOpen,
    }
  );

  return (
    <div className={modalClass} style={{ touchAction: "manipulation" }}>
      <div className="flex items-center justify-center h-full p-0">
        <div
          className="bg-[#ffffff30] h-full rounded-lg w-full "
          // onClick={onClose} -- Uncomment if `onClose` functionality is desired
        >
          <div className=" h-full flex items-center">{children}</div>
        </div>
      </div>
    </div>
  );
};

FullModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default FullModal;

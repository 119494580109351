import { createSlice } from "@reduxjs/toolkit";

interface ClientState {
  loading: boolean;
  isAuth: any;
  user: any;
  token: any;
  email: any;
  socials: any;
  priceAlerts: any;
}

const initialState: ClientState = {
  loading: false,
  isAuth: false,
  user: null,
  token: null,
  email: null,
  socials: null,
  priceAlerts: [],
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    userJoin: (state, action) => {
      state.loading = false;
      state.email = action.payload.email;
    },
    userSocialJoin: (state, action) => {
      state.loading = false;
      state.user = action.payload.client;
      state.token = action.payload.token;
      state.isAuth = true;
      localStorage.setItem("svToken", action.payload.token);
    },
    userverified: (state, action) => {
      state.loading = false;
      state.user = action.payload.client;
      state.token = action.payload.token;
      state.isAuth = true;
      localStorage.setItem("svToken", action.payload.token);
    },
    userLoaded: (state, action) => {
      state.loading = false;
      state.isAuth = true;
      state.user = action.payload.user;
      state.socials = action.payload.socials;
    },
    userUpdated: (state, action) => {
      state.loading = false;
      state.user = action.payload.result;
    },
    logout: (state) => {
      state.loading = false;
      state.isAuth = false;
      state.user = null;
      state.token = null;
      state.email = null;
      state.socials = null;
      localStorage.removeItem("svToken");
    },
    priceAlertCreated: (state) => {
      state.loading = false;
    },
    priceAlertRemoved: (state) => {
      state.loading = false;
    },
    priceAlertLoaded: (state, action) => {
      state.loading = false;
      state.priceAlerts = action.payload;
    },
    failed: (state) => {
      state.loading = false;
    },
  },
});

export const {
  loading,
  userUpdated,
  userJoin,
  failed,
  userverified,
  userLoaded,
  userSocialJoin,
  priceAlertCreated,
  logout,
  priceAlertLoaded,
  priceAlertRemoved,
} = clientSlice.actions;

export default clientSlice.reducer;

const BasicInputSolid = ({
  placeholder,
  value,
  onChange,
  type = "text",
}: any) => {
  return (
    <div>
      <input
        className={
          " bg-[#fff] border-[#000046] border-[1px] h-12 rounded-[4px] w-full text-[#171717] px-2 "
        }
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};
export default BasicInputSolid;

import { baseUrl } from "../airport/apis";

export const requeryFlightUrl = `${baseUrl}/flight/requery/ `;
export const vendorUrl = `${baseUrl}/trans/vendors`;
export const gatewayIntentUrl = `${baseUrl}/trans/gateway/intent`;
export const paymentIntentUrl = `${baseUrl}/trans/intent_final`;
export const paymentVerifyUrl = `${baseUrl}/trans/verify`;
export const gatewayProcessUrl = `${baseUrl}/trans/gateway/process`;
export const historyUrl = `${baseUrl}/trans/history`;
export const flightHistoryDetailUrl = `${baseUrl}/trans/flight/`;
export const flightCancelUrl = `${baseUrl}/flight/cancel/`;

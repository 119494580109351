/* @ts-ignore */
import UserAvatar from "react-user-avatar";

import { useSelector } from "react-redux";
import { useModal } from "../Sliders/Modal/ModalContext";

const Currency = () => {
  const { showModal } = useModal();
  const currency = useSelector((state: any) => state.app.currency);
  return (
    <div
      className="hover:bg-[#ffffff40] hover:rounded px-2 hidden md:flex justify-center items-center cursor-pointer"
      onClick={() => {
        showModal("currencyMainModal");
      }}
    >
      <span className="font-semibold text-base">
        {currency ? currency.code : "N/A"}
      </span>
    </div>
  );
};

export default Currency;

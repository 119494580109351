import {
  failed,
  loading,
  cityLoaded,
  airportLoaded,
  countryLoaded,
  currencySelect,
  providersLoaded,
  clientInfoLoaded,
  airportRouteLoaded,
  airlineSearchLoaded,
  clientAirportLoaded,
  pastSearchLoaded,
  frequentTravelerLoaded,
  clientCountryInfoLoaded,
} from "./appSlice";
import Axios from "axios";
import {
  loadCityUrl,
  loadAirportUrl,
  loadProviderUrl,
  loadCountriesUrl,
  getClientInfoUrl,
  getairportRouteUrl,
  getAirlineSearchUrl,
  clientAirportUrl,
  clientPastSearches,
  freqeuntTraveler,
  getClientCurrencyInfo,
} from "./apis";
import { tokenConfig } from "../actions";

export const selectCurrency = (payload: any) => (dispatch: any) => {
  dispatch(currencySelect(payload));
};

export const getClientInfo = () => (dispatch: any) => {
  dispatch(loading());
  Axios.get(getClientInfoUrl)
    .then((res) => {
      dispatch(clientInfoLoaded(res.data));
      dispatch(getClientCurrInfo(res.data.country_code));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};
export const getClientCurrInfo = (code: any) => (dispatch: any) => {
  dispatch(loading());
  Axios.get(getClientCurrencyInfo + code)
    .then((res) => {
      dispatch(clientCountryInfoLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};
export const getUserPastSearches = () => (dispatch: any) => {
  dispatch(loading());
  let x = localStorage.getItem("deviceId");
  Axios.get(clientPastSearches + x)
    .then((res) => {
      dispatch(pastSearchLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};

export const getFrequentTraveler = (email: any) => (dispatch: any) => {
  dispatch(loading());
  Axios.get(freqeuntTraveler + email, tokenConfig())
    .then((res) => {
      dispatch(frequentTravelerLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};
export const getClientAirportInfo = (search: any) => (dispatch: any) => {
  dispatch(loading());
  Axios.get(clientAirportUrl, { params: { search } })
    .then((res) => {
      dispatch(clientAirportLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};
export const loadCity = () => (dispatch: any) => {
  dispatch(loading());
  Axios.get(loadCityUrl)
    .then((res) => {
      dispatch(cityLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};
export const loadAirportRoute = (route: any, func: any) => (dispatch: any) => {
  dispatch(loading());
  Axios.get(getairportRouteUrl, { params: { code: route } })
    .then((res) => {
      dispatch(airportRouteLoaded(res.data));
      func(res.data);
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};
export const loadCountries = () => (dispatch: any) => {
  dispatch(loading());
  Axios.get(loadCountriesUrl)
    .then((res) => {
      dispatch(countryLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};
export const loadAirport = () => (dispatch: any) => {
  dispatch(loading());
  Axios.get(loadAirportUrl)
    .then((res) => {
      dispatch(airportLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};
export const loadAirline = (search: any) => (dispatch: any) => {
  dispatch(loading());
  Axios.get(getAirlineSearchUrl, { params: { search: search } })
    .then((res) => {
      dispatch(airlineSearchLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};

export const loadProvider = () => (dispatch: any) => {
  dispatch(loading());
  Axios.get(loadProviderUrl)
    .then((res) => {
      dispatch(providersLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err));
    });
};

export const arrayToDiction = (data: any, payload: any) => {
  let newData = data;
  for (const item of payload) {
    if (newData) {
      newData = { ...newData, [Object.keys(newData).length + 1]: item };
    } else {
      newData = { 1: item };
    }
  }

  return newData;
};

export const sortByTotalPriceAscending = (data: any) => {
  // Use the sort function to compare the 'total' property of each item in the array
  Object.keys(data).sort((a: any, b: any) => {
    const totalPriceA = parseFloat(data[a].price.total);
    const totalPriceB = parseFloat(data[b].price.total);

    // Compare the total prices
    return totalPriceA - totalPriceB;
  });

  return data;
};

export const unionArrays = (arr1: any, arr2: any) => {
  // Use a Set to store unique elements from both arrays
  const uniqueSet = new Set([...arr1, ...arr2]);

  // Convert the Set back to an array
  const unionArray = Array.from(uniqueSet);

  return unionArray;
};

export const sortVariant = (data: any) => {
  let sorted: any = {};

  for (const item of data) {
    if (sorted[item.variantid]) {
      sorted[item.variantid] = [...sorted[item.variantid], item.reference];
    } else {
      sorted[item.variantid] = [item.reference];
    }
  }
  return sorted;
};

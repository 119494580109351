import { useSelector } from "react-redux";
import Footer1 from "../Layout/Footer/Footer1";
import NavbarTransparent from "../Layout/Navbar2/NavbarTransparent";
import Banner from "./Banner";

const Terms = () => {
  const currency = useSelector((state: any) => state.app.currency);
  return (
    <div className="relative w-full">
      <NavbarTransparent />
      <Banner />

      <div
        className="flex flex-col   -mt-32 rounded-t-[32px]  bg-slate-100"
        style={{ position: "inherit" }}
      >
        <div className="z-[1] flex  -mt-28 inset-0 flex-col items-center justify-center">
          <div className="w-[100%] z-[1] md:w-[90%] lg:w-[75%] xl:w-2/3 mb-2 mt-2 pb-5  h-auto  items-center">
            <div className=" mt-6 items-start text-white font-bold flex text-3xl w-full px-3">
              Terms of Service
            </div>
          </div>
        </div>

        <div className="flex w-[100%] md:w-[90%] lg:w-[75%] xl:w-2/3 m-auto mt-2 md:mt-10">
          <div className="container mx-auto my-2 p-6 bg-white shadow-lg rounded-lg text-justify">
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              1. Introduction
            </h3>
            <p className="mb-2">
              These Terms and Conditions ("Terms") govern your access to and use
              of the StaviGo travel booking platform ("Platform"), operated by
              {currency?.country === "NG"
                ? " Black Silicon Limited"
                : " BlackSilicon LLC"}{" "}
              . For the purposes of this Agreement, (a) “we”, “us”, “our”,
              “StaviGo”, and “Stavigo.com” refer to StaviGo, (b) “you” or “your”
              refers to you as the user of our Website, (c) “including” or
              “includes” means including without limitation, and (d) “Bookings”
              means any goods or services (including airline tickets and hotel
              bookings) purchased or reserved through the Website.
            </p>
            <p className="mb-2">
              In simpler terms: This agreement outlines the rules for using
              StaviGo's website, mobile app, and other features (collectively,
              the "Platform"). We provide a platform to search travel
              information and book travel-related services for your personal
              use. By using StaviGo, you agree to these Terms and Conditions,
              which form a binding contract between you and StaviGo. If you
              disagree with any part of these Terms, you may not access or use
              the Platform.
            </p>
            <h3 className="text-lg font-semibold text-[#000046] mt-6 mb-4">
              2. Use of Platform
            </h3>
            <ul className="list-disc pl-6 mb-4">
              <li>
                You must be at least 18 years old and have the legal authority
                to enter into contracts to use the Platform.
              </li>
              <li>
                You agree to use the Platform for legitimate travel purposes
                only and not for any illegal or unauthorized purpose.
              </li>
              <li>
                You are responsible for maintaining the confidentiality of your
                account information and for all activity that occurs under your
                account.
              </li>
              <li>
                You agree not to use the Platform in any way that disrupts or
                interferes with its operation.
              </li>{" "}
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mt-6 mb-4">
              3. Travel Services
            </h3>
            <ul className="list-disc pl-6 mb-4">
              <li>
                StaviGo acts as an intermediary, connecting you with travel
                service providers ("Suppliers") such as airlines, hotels, car
                rentals, and activity providers.
              </li>
              <li>
                We do not own or operate any of the travel services offered on
                the Platform.
              </li>
              <li>
                All bookings are subject to the Supplier's terms and conditions,
                which may be found on their websites or provided during the
                booking process.
              </li>
              <li>
                You acknowledge that StaviGo is not responsible for the
                performance of any travel services provided by Suppliers.
              </li>{" "}
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mt-6 mb-4">
              4. Prices and Payment
            </h3>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Prices displayed on the Platform are quoted in the specified
                currency and may vary depending on availability, taxes, and
                fees.{" "}
              </li>
              <li>
                You are responsible for all charges incurred for bookings made
                through the Platform, including any applicable taxes, fees, and
                foreign exchange charges.
              </li>
              <li>
                StaviGo may use a third-party payment processor to process your
                payments. You agree to comply with the terms and conditions of
                the payment processor.
              </li>
              <li>
                Payment for bookings made through StaviGo is processed securely
                using industry-standard encryption technology. By providing your
                payment information, you authorize us to charge the applicable
                fees to your chosen payment method.
              </li>{" "}
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mt-6 mb-4">
              5. Cancellations and Changes
            </h3>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Cancellation and change policies vary depending on the Supplier
                and the specific service booked.
              </li>
              <li>
                You are responsible for reviewing the Supplier's cancellation
                and change policies before making a booking.
              </li>
              <li>
                StaviGo may charge a cancellation or change fee for bookings
                made through the Platform.
              </li>
            </ul>{" "}
            <h3 className="text-lg font-semibold text-[#000046] mt-6 mb-4">
              6. Disclaimer of Warranties
            </h3>
            <ul className="list-disc pl-6 mb-4">
              <li>
                StaviGo makes no warranties, express or implied, regarding the
                Platform or any travel services offered through the Platform.
              </li>
              <li>
                The Platform and travel services are provided "as is" and
                without warranties of any kind, including merchantability,
                fitness for a particular purpose, or non-infringement.
              </li>
              <li>
                StaviGo does not warrant that the Platform will be
                uninterrupted, error-free, or virus-free.
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mt-6 mb-4">
              7. Limitation of Liability
            </h3>
            <ul className="list-disc pl-6 mb-4">
              <li>
                StaviGo shall not be liable for any direct, indirect,
                incidental, special, consequential, or exemplary damages arising
                out of or relating to your use of the Platform or any travel
                services offered through the Platform.
              </li>
              <li>
                This limitation of liability applies, without limitation, to
                damages for loss of profits, loss of data, business
                interruption, personal injury, property damage, and wrongful
                death.
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              8. Governing Law
            </h3>
            <p className="mb-2">
              These Terms shall be governed by and construed in accordance with
              the laws of the{" "}
              {currency?.country === "NG"
                ? "Federal Republic of Nigeria"
                : "United States of America"}{" "}
              , without regard to its conflict of laws provisions.
            </p>
            <h3 className="text-lg font-semibold text-[#000046] mt-6 mb-4">
              9. Dispute Resolution
            </h3>
            <ul className="list-disc pl-6 mb-4">
              <li>
                These Terms and any dispute or claim (including non-contractual
                disputes or claims) arising out of them, their subject matter,
                or formation shall be governed by and construed in accordance
                with the law of the{" "}
                {currency?.country === "NG"
                  ? " Federal Republic of Nigeria"
                  : " United States of America"}
                .
              </li>
              <li>
                You and we both agree that the courts of the Federal Republic of
                Nigeria will have jurisdiction over any dispute or claim
                (including non-contractual disputes or claims) arising out of or
                in connection with these Terms or their subject matter or
                formation.
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              10. Entire Agreement
            </h3>
            <p className="mb-2">
              These Terms constitute the entire agreement between you and
              StaviGo with respect to your use of the Platform.
            </p>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              11. Amendments
            </h3>
            <p className="mb-2">
              These Terms may be subject to change. The amended Terms shall be
              effective from and after the date they are posted on our Website.
              We recommend that you review these periodically. Your continued
              use of the Platform following the posting of revised Terms
              constitutes your acceptance of the changes.
            </p>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              12. Severability
            </h3>
            <p className="mb-2">
              If any provision of these Terms is held to be invalid or
              unenforceable, such provision shall be struck and the remaining
              provisions shall remain in full force and effect.
            </p>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              13. Force Majeure
            </h3>
            <p className="mb-2">
              StaviGo shall not be liable for any delay or failure to perform
              its obligations under these Terms due to causes beyond its
              reasonable control, including acts of God, war, terrorism,
              strikes, or natural disasters.
            </p>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              14. International Considerations
            </h3>
            <ul>
              <li>
                You are responsible for complying with all applicable laws and
                regulations of your home country and any country you visit while
                using the Platform.
              </li>
              <li>
                StaviGo may be required to comply with data privacy laws and
                regulations applicable in various jurisdictions.
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              15. Account Registration
            </h3>
            <ul>
              <li>
                You must create an account to access certain features of
                StaviGo. You agree to provide accurate, current, and complete
                information during the registration process and to update such
                information to keep it accurate, current, and complete.
              </li>
              <li>
                You are responsible for maintaining the confidentiality of your
                account credentials and for all activities that occur under your
                account. You agree to notify us immediately of any unauthorized
                use of your account or any other breach of security.
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              16. Booking and Reservations
            </h3>
            <ul>
              <li>
                StaviGo provides a platform for users to search for and book
                travel services, including flights, hotels, rental cars, and
                activities. All bookings made through StaviGo are subject to
                availability and confirmation by the respective service
                provider.
              </li>
              <li>
                By making a booking through StaviGo, you agree to abide by the
                terms and conditions of the service provider, including
                cancellation policies, refund policies, and any other terms
                specific to the booked service.
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              17. User Conduct
            </h3>
            <ul>
              <li>
                You agree not to use StaviGo for any unlawful or prohibited
                purpose or to engage in any activity that interferes with the
                proper functioning of the platform or the rights of other users.
              </li>
              <li>
                You agree not to use any automated means, including bots,
                spiders, or scrapers, to access or interact with StaviGo without
                our express written permission.
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              18. Intellectual Property
            </h3>
            <ul>
              <li>
                All content and materials available on StaviGo, including text,
                graphics, logos, images, and software, are the property of
                StaviGo or its licensors and are protected by copyright and
                other intellectual property laws.
              </li>
              <li>
                You may not modify, reproduce, distribute, transmit, display,
                perform, or create derivative works of any content or materials
                from StaviGo without our prior written consent.
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              19. Disclaimer of Warranties
            </h3>
            <ul>
              <li>
                StaviGo is provided on an "as is" and "as available" basis,
                without any warranties of any kind, express or implied. We do
                not warrant that StaviGo will be uninterrupted or error-free or
                that any defects will be corrected.
              </li>
              <li>
                We make no representations or warranties about the accuracy,
                reliability, completeness, or timeliness of the content or
                materials available on StaviGo or about the quality or
                suitability of any services offered through the platform.
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              20. Limitation of Liability
            </h3>
            <ul>
              <li>
                In no event shall StaviGo, its officers, directors, employees,
                agents, or affiliates be liable for any indirect, incidental,
                special, consequential, or punitive damages arising out of or in
                connection with your use of StaviGo or the services offered
                through it.
              </li>
              <li>
                Our total liability to you for any claim arising out of or in
                connection with these Terms and Conditions or your use of
                StaviGo shall not exceed the amount paid by you for the services
                in question.
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              21. Waiver
            </h3>
            <ul>
              <li>
                By using the Platform, you agree to irrevocably waive any claim
                against StaviGo, its subsidiaries, affiliates, and any of their
                respective officers, directors, managers, agents, contractors,
                or employees, and expressly agree that none of these parties
                shall be held liable for:
                <ul>
                  <li>
                    Any loss of or damage to property or injury to any person
                    caused by any defect, negligence, or other wrongful act or
                    omission of any Travel Supplier;
                  </li>
                  <li>
                    Any inconvenience, loss of enjoyment, mental distress, or
                    other similar matter;
                  </li>
                  <li>
                    Any delayed departure, missed connections, substitutions of
                    accommodations, terminations of service, or changes in fares
                    and rates;
                  </li>
                  <li>
                    Any cancellation or double-booking of reservations or
                    tickets beyond the reasonable control of StaviGo;
                  </li>
                  <li>
                    Any claim arising out of or in connection with air or other
                    transportation services, products, or other features
                    performed (or not) in connection with your itinerary.
                  </li>
                </ul>
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              22. Indemnification
            </h3>
            <ul>
              <li>
                You agree to protect and indemnify StaviGo, its affiliates,
                partners, joint ventures, and/or their respective suppliers and
                any of their respective officers, directors, managers,
                employees, and agents from and against any claims, causes of
                action, demands, recoveries, losses, damages, fines, penalties,
                or other costs or expenses of any kind or nature, including but
                not limited to reasonable legal and accounting fees, brought by:
                <ul>
                  <li>
                    You or on your behalf in excess of the liability described
                    above, provided that such limitation of liability is
                    permitted by the law of your state of residence;
                  </li>
                  <li>
                    Third parties as a result of your breach of these Terms &
                    Conditions, notices, or documents referenced on the
                    Platform;
                  </li>
                  <li>
                    Your violation of any law or the rights of a third party;
                  </li>
                  <li>Your use of the Platform.</li>
                </ul>
              </li>
            </ul>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              23. Changes to Terms and Conditions
            </h3>
            <p className="mb-2">
              We reserve the right to modify or update these Terms and
              Conditions at any time, without prior notice. Any changes will be
              effective immediately upon posting on StaviGo. Your continued use
              of StaviGo after any such changes constitutes your acceptance of
              the new Terms and Conditions.
            </p>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              23. Contact Information
            </h3>
            <p className="mb-2">
              If you have any questions or concerns about these Terms and
              Conditions, please contact us at support@stavigo.com or through
              our contact number{" "}
              <a
                href={`tel:${
                  currency?.country === "NG"
                    ? "+234 (201) 350-3000"
                    : "+1 (888) 665-3405"
                }`}
              >
                {currency?.country === "NG"
                  ? "+234 (201) 350-3000"
                  : "+1 (888) 665-3405"}
              </a>{" "}
              .
            </p>
            <h3 className="text-lg font-semibold text-[#000046] mb-4">
              25. General
            </h3>
            <ul>
              <li>
                These Terms are between you and us. No other person shall have
                any rights to enforce any of these Terms. If any court or
                relevant authority finds that part of these Terms is illegal,
                the rest will continue in full force and effect.
              </li>
              <li>
                Even if we delay enforcing these Terms, we may still take steps
                against you at a later date.
              </li>
            </ul>
          </div>
        </div>
        <Footer1 />
      </div>
    </div>
  );
};
export default Terms;

import { baseUrl } from "../airport/apis";

export const loadCityUrl = `${baseUrl}/flight/city`;
export const loadAirportUrl = `${baseUrl}/flight/airport/all`;
export const loadProviderUrl = `${baseUrl}/provider/all`;
export const loadCountriesUrl = `${baseUrl}/country`;
export const getClientInfoUrl = `${baseUrl}/client/info`;
export const getairportRouteUrl = `${baseUrl}/flight/airport_route`;
export const getAirlineSearchUrl = `${baseUrl}flight/airline_search`;
export const clientAirportUrl = `${baseUrl}/flight/airport`;
export const clientPastSearches = `${baseUrl}/deal/past_searches/`;
export const freqeuntTraveler = `${baseUrl}/deal/freq_traveler/`;
export const getClientCurrencyInfo = `${baseUrl}/country/ip/`;

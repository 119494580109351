(function () {
  if (typeof global === "undefined") {
    window.global = window;
  }
})();
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-google-flight-datepicker/dist/main.css";
import "rsuite/dist/rsuite.min.css";
import { Provider } from "react-redux";
import store from "./store/store.ts";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <HelmetProvider>
    <Provider store={store}>
      <GoogleOAuthProvider clientId="675097151498-r3dtmum7c9d3db37tkdkcao68m0cpnhn.apps.googleusercontent.com">
        <ToastContainer />

        <App />
      </GoogleOAuthProvider>
    </Provider>
  </HelmetProvider>
);

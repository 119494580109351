import { sortVariant } from "../store/flight/flightHandler";
import { calCulateOverallprice, extractFirstStrings } from "./Helpers2";

export const sortToReference = (offers: any) => {
  const sorted: any = {};
  for (const item of offers) {
    sorted[item.reference] = item;
  }
  return sorted;
};

export const filterOffer = (data: any) => {
  let filters = {
    stops: {
      direct: [],
      "1_stop": [],
      "2_stop": [],
      "3+_stop": [],
      ...sortByStops(data),
    },
    time: sortByDepartureTime(data),
    stopovers: sortByStopovers(data),
    airlines: sortByAirlines(data),
  };

  return filters;
};
export const sortOffer = (data: any, refData: any) => {
  let filters = {
    recommended: recommendFlights(data, refData),
    lowest: sortFlightsByLowestPrice(data, refData),
    direct: getDirectFlights(data, refData),
    shortest: getFlightsSortedByDuration(data, refData),
  };
  return filters;
};
function getFlightsSortedByDuration(flights: any, refData: any) {
  let filt = sortVariant(flights);
  let exFilt = extractFirstStrings(filt);
  return exFilt
    .sort((a, b) => {
      const aData = refData[a];
      const bData = refData[b];

      const durationA = aData.itineraries.reduce(
        (sum: any, itinerary: any) =>
          sum +
          itinerary.segments.reduce(
            (sumSeg: any, seg: any) => sumSeg + seg.duration,
            0
          ),
        0
      );
      const durationB = bData.itineraries.reduce(
        (sum: any, itinerary: any) =>
          sum +
          itinerary.segments.reduce(
            (sumSeg: any, seg: any) => sumSeg + seg.duration,
            0
          ),
        0
      );

      return durationA - durationB;
    })
    .map((flight) => flight);
}

function recommendFlights(flights: any, refData: any) {
  let filt = sortVariant(flights);
  let exFilt = extractFirstStrings(filt);
  return exFilt
    .sort((a, b) => {
      const aData = refData[a];
      const bData = refData[b];

      const durationA = aData.itineraries.reduce(
        (sum: any, itinerary: any) =>
          sum +
          itinerary.segments.reduce(
            (sumSeg: any, seg: any) => sumSeg + seg.duration,
            0
          ),
        0
      );
      const durationB = bData.itineraries.reduce(
        (sum: any, itinerary: any) =>
          sum +
          itinerary.segments.reduce(
            (sumSeg: any, seg: any) => sumSeg + seg.duration,
            0
          ),
        0
      );

      const priceA = parseFloat(calCulateOverallprice(aData.travelers));
      const priceB = parseFloat(calCulateOverallprice(bData.travelers));

      // Priority on duration first, then price
      if (durationA === durationB) {
        return priceA - priceB;
      } else {
        return durationA - durationB;
      }
    })
    .map((flight) => flight);
}
function sortFlightsByLowestPrice(flights: any, refData: any) {
  let filt = sortVariant(flights);
  let exFilt = extractFirstStrings(filt);
  return exFilt
    .slice()
    .sort(
      (a: any, b: any) =>
        parseFloat(calCulateOverallprice(refData[a].travelers)) -
        parseFloat(calCulateOverallprice(refData[b].travelers))
    );
}
function getDirectFlights(flights: any, refData: any) {
  let filt = sortVariant(flights);
  let exFilt = extractFirstStrings(filt);
  return exFilt.filter((flight: any) =>
    refData[flight].itineraries.every(
      (itinerary: any) => itinerary.segments.length === 1
    )
  );
}

const sortByAirlines = (data: any) => {
  let sorted: any = {};
  for (const item of Object.keys(data)) {
    for (const x of data[item].itineraries) {
      let key = x.segments[0].carrierCode;
      if (sorted[key]) {
        sorted[key] = [...sorted[key], item];
      } else {
        sorted[key] = [item];
      }
    }
  }

  return sorted;
};
export const sortByAirlinesKeys = (data: any, offers: any) => {
  let sorted: any = {};
  for (const item of data) {
    for (const x of offers[item].itineraries) {
      let key = x.segments[0].carrierCode;
      if (sorted[key]) {
        sorted[key] = [...sorted[key], item];
      } else {
        sorted[key] = [item];
      }
    }
  }

  return sorted;
};
export function getFirstStringFromArrays(data: any) {
  const result: any = {};
  for (const key in data) {
    const valuesArray = data[key];
    if (valuesArray.length > 0) {
      result[key] = valuesArray[0]; // Get the first string
    } else {
      result[key] = null; // Handle empty arrays (optional)
    }
  }
  return result;
}

const sortByDepartureTime = (data: any) => {
  let sorted: any = {};
  for (const item of Object.keys(data)) {
    for (const x of data[item].itineraries) {
      let key = getTimeOfDay(x.segments[0].departuredatetime);
      if (sorted[key]) {
        sorted[key] = [...sorted[key], item];
      } else {
        sorted[key] = [item];
      }
    }
  }
  return sorted;
};

export const sortByStops = (data: any) => {
  let sorted: any = {};
  for (const item of Object.keys(data)) {
    for (const x of data[item].itineraries) {
      let key =
        x.segments.length - 1 === 0
          ? "direct"
          : x.segments.length - 1 > 2
          ? "3+_stop"
          : x.segments.length - 1 + `_stop`;
      if (sorted[key]) {
        sorted[key] = [...sorted[key], item];
      } else {
        sorted[key] = [item];
      }
    }
  }

  return sorted;
};

export const sortByStopovers = (data: any) => {
  let sorted: any = {};
  for (const item of Object.keys(data)) {
    for (const x of data[item].itineraries) {
      let size = x.segments.length;
      if (size > 1) {
        for (const seg of x.segments.slice(1)) {
          let key = seg.departurecode;
          if (sorted[key]) {
            sorted[key] = [...sorted[key], item];
          } else {
            sorted[key] = [item];
          }
        }
      }
    }
  }

  return sorted;
};

const getTimeOfDay = (dateTimeString: any) => {
  const currentHour = new Date(dateTimeString).getHours();

  // Define hour ranges for morning, afternoon, and evening
  const morningEnd = 12;
  const afternoonEnd = 17;
  const eveningEnd = 20;

  // Compare the current hour to determine the time of day
  if (currentHour < morningEnd) {
    return "morning";
  } else if (currentHour < afternoonEnd) {
    return "afternoon";
  } else if (currentHour < eveningEnd) {
    return "evening";
  } else {
    return "night";
  }
};

export const offerMean = (data: any) => {
  let total = 0.0;
  for (const item of Object.keys(data)) {
    total = total + parseFloat(data[item].price.total);
  }

  return total / data.length;
};

export const calculateTotalDuration = (segments: any) => {
  if (!Array.isArray(segments)) {
    throw new Error("Invalid input. Please provide an array of segments.");
  }

  // Use reduce to sum up the durations
  const totalMinutes = segments.reduce((total, segment) => {
    // Ensure that each segment object has a 'duration' property
    if (!segment || typeof segment.duration !== "string") {
      throw new Error(
        "Invalid segment object. Please provide segments with valid duration property."
      );
    }

    // Parse the duration and add it to the total
    const durationParts = segment.duration.match(/(\d+)([Hh])?(\d+)?/);
    if (durationParts) {
      const hours = parseInt(durationParts[1], 10) || 0;
      const minutes = parseInt(durationParts[3], 10) || 0;
      return total + hours * 60 + minutes;
    } else {
      throw new Error(
        "Invalid duration format in segment object. Please provide valid duration values."
      );
    }
  }, 0);

  // Calculate hours and minutes from total minutes
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Format as "hhmm"
  const formattedDuration = `${hours.toString().padStart(2, "0")}h${minutes
    .toString()
    .padStart(2, "0")}m`;

  return formattedDuration;
};

export const formatPassengerToTravelers = (passengerInfo: any) => {
  const travelers = [];
  let idx = 1;
  for (const [travelerType, count] of Object.entries(passengerInfo)) {
    /* @ts-ignore */
    if (count > 0) {
      const traveler = {
        quantity: count,
        type: travelerType.toUpperCase(),
      };
      travelers.push(traveler);
    }

    idx++;
  }

  return travelers;
};

export const getCabinFromSegment = (data: any) => {
  let cabin = "";
  for (const item of data) {
    cabin = !cabin.includes(item.cabin)
      ? cabin.concat(`${item.cabin}/`)
      : cabin;
  }
  cabin = cabin.substring(0, cabin.length - 1);
  cabin = cabin.replace("_", " ");
  return cabin;
};

import { createSlice } from "@reduxjs/toolkit";
import { getDateRange } from "../../util/Helpers";
import moment from "moment";
import {
  filterOffer,
  sortOffer,
  sortToReference,
} from "../../util/FilterOffer";
import { sortVariant, unionArrays } from "./flightHandler";

interface FlightState {
  loading: boolean;
  offers: any;
  dateRange: any;
  lastSearchTime: any;
  dictionaries: any;
  offerFiltered: any;
  filtered: any;
  filterResult: any;
  filteredSet: any;
  offer: any;
  additionalinfo: any;
  variants: any;
  instaSort: any;
}

const initialState: FlightState = {
  loading: false,
  offers: null,
  offerFiltered: null,
  dictionaries: null,
  dateRange: getDateRange(),
  lastSearchTime: moment(new Date()).format("hh:mm:ss MMMM DD, YYYY"),
  filtered: [],
  filteredSet: [],
  filterResult: null,
  offer: null,
  additionalinfo: false,
  variants: null,
  instaSort: null,
};

const flightSlice = createSlice({
  name: "flight",
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true;
      state.offers = null;
      state.offerFiltered = null;
      state.dictionaries = null;
      state.filtered = [];
      state.filteredSet = [];
      state.filterResult = null;
      state.offer = null;
    },

    failed: (state) => {
      state.loading = false;
    },

    offersLoaded: (state, action) => {
      let x = action.payload.offers;

      state.offers = sortToReference(x);
      state.offerFiltered = filterOffer(sortToReference(x));
      state.instaSort = sortOffer(x, sortToReference(x));
      state.dictionaries = action.payload.dictionary;
      state.lastSearchTime = new Date();
      state.additionalinfo = action.payload.additionalinfo;
      state.variants = sortVariant(x);
      state.loading = false;
    },
    filterSet: (state, action) => {
      if (!state.filtered.includes(action.payload.unit)) {
        state.filtered = [...state.filtered, action.payload.unit];
        state.filteredSet = [
          ...state.filteredSet,
          [action.payload.key, action.payload.unit],
        ];
      } else {
        state.filtered = state.filtered.filter(
          (item: any) => item !== action.payload.unit
        );
        state.filteredSet = state.filteredSet.filter(
          (item: any) => item[1] !== action.payload.unit
        );
      }
    },
    filterResultSet: (state) => {
      let x: any = [];
      if (state.filteredSet.length === 0) {
        state.filterResult = null;
      } else {
        for (const item of state.filteredSet) {
          let item01 = item[0];
          let item02 = item[1];
          let res = state.offerFiltered[item01][item02];
          x = unionArrays(x, res);

          state.filterResult = x;
        }
      }
    },
    offerSelected: (state, action) => {
      state.offer = action.payload;
    },
    lazyLoading: (state) => {
      state.loading = true;
    },
    lazyLoaded: (state) => {
      state.loading = false;
    },
    flightReset: (state) => {
      state.loading = false;

      state.offers = null;
      state.offerFiltered = null;
      state.dictionaries = null;
      (state.lastSearchTime = moment(new Date()).format(
        "hh:mm:ss MMMM DD, YYYY"
      )),
        (state.filtered = []);
      state.filteredSet = [];
      (state.filterResult = null), (state.offer = null);
      state.additionalinfo = false;
      state.variants = null;
    },
  },
});

export const {
  failed,
  loading,
  offersLoaded,
  filterSet,
  filterResultSet,
  offerSelected,
  lazyLoading,
  lazyLoaded,
  flightReset,
} = flightSlice.actions;

export default flightSlice.reducer;

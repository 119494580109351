import { configureStore } from "@reduxjs/toolkit";
import appSlice from "./app/appSlice";
import airportSlice from "./airport/airportSlice";
import flightSlice from "./flight/flightSlice";
import upsellSlice from "./upsell/upsellSlice";
import formSlice from "./form/formSlice";
import orderSlice from "./order/orderSlice";
import clientSlice from "./client/clientSlice";
import miscSlice from "./misc/miscSlice";
import notificationSlice from "./notification/notificationSlice";
import holdSlice from "./hold/holdSlice";

const store = configureStore({
  reducer: {
    app: appSlice,
    hold: holdSlice,
    airport: airportSlice,
    flight: flightSlice,
    upsell: upsellSlice,
    form: formSlice,
    order: orderSlice,
    client: clientSlice,
    misc: miscSlice,
    notification: notificationSlice,
  },
});

export default store;

import { failed, loading, transLoaded, ofcQueried } from "./holdSlice";
import Axios from "axios";
import { holdDetailsUrl, ofcAppendUrl, queryOfcUrl } from "./apis";

export const loadHoldDetails =
  (data: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.post(holdDetailsUrl, data)
      .then((res) => {
        dispatch(transLoaded(res.data));
        func(res.data);
      })
      .catch((err) => {
        errFunc(err);

        dispatch(failed(err.response.data));
      });
  };

export const ofcAppend = (data: any) => {
  Axios.post(ofcAppendUrl, data);
};

export const ofcQuery = (data: any) => (dispatch: any) => {
  dispatch(loading());
  Axios.post(queryOfcUrl, data)
    .then((res) => {
      dispatch(ofcQueried({ res: res.data, query: data.queryType }));
    })
    .catch((err) => {
      dispatch(failed(err.response.data));
    });
};

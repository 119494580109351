import {
  pageSet,
  flightRequery,
  failed,
  loading,
  gatewayIntent,
  gatewayProcess,
  vendorLoaded,
  vendorSelect,
  historyLoaded,
  historySelected,
  historyDetailsLoaded,
  orderReset,
  flightCancel,
  intentFinal,
} from "./orderSlice";
import {
  requeryFlightUrl,
  gatewayIntentUrl,
  gatewayProcessUrl,
  vendorUrl,
  historyUrl,
  flightHistoryDetailUrl,
  flightCancelUrl,
  paymentVerifyUrl,
  paymentIntentUrl,
} from "./apis";
import Axios from "axios";
import { tokenConfig } from "../actions";

export const requeryFlight =
  (data: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.post(requeryFlightUrl, data)
      .then((res) => {
        dispatch(flightRequery(res.data));
        func(res.data);
      })
      .catch((err) => {
        dispatch(failed(err.response));
        errFunc(err.response.data);
      });
  };
export const getFlightDetails = (ref: any, errFunc: any) => (dispatch: any) => {
  dispatch(loading());
  Axios.get(flightHistoryDetailUrl + ref, tokenConfig())
    .then((res) => {
      dispatch(historyDetailsLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err.response));
      errFunc(err.response.data);
    });
};
export const loadHistory = () => (dispatch: any) => {
  dispatch(loading());
  Axios.get(historyUrl, tokenConfig())
    .then((res) => {
      dispatch(historyLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err.response));
    });
};

export const intentGateway =
  (data: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.post(gatewayIntentUrl, data)
      .then((res) => {
        dispatch(gatewayIntent(res.data));

        func(res.data);
      })
      .catch((err) => {
        dispatch(failed(err.response));
        errFunc(err.response.data);
      });
  };
export const paymentIntiation =
  (data: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.post(paymentIntentUrl, data)
      .then((res) => {
        dispatch(intentFinal(res.data));

        func(res.data);
      })
      .catch((err) => {
        dispatch(failed(err.response));
        errFunc(err.response.data);
      });
  };
export const processGateway =
  (data: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.post(gatewayProcessUrl, data)
      .then((res) => {
        dispatch(gatewayProcess(res.data));
        func(res.data);
      })
      .catch((err) => {
        dispatch(failed(err.response));
        errFunc(err.response.data);
      });
  };
export const verifyPayment =
  (data: any, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.post(paymentVerifyUrl, data)
      .then((res) => {
        dispatch(gatewayProcess(res.data));
        func(res.data);
      })
      .catch((err) => {
        dispatch(failed(err.response));
        errFunc(err.response.data);
      });
  };
export const loadVendor = () => (dispatch: any) => {
  dispatch(loading());
  Axios.get(vendorUrl)
    .then((res) => {
      dispatch(vendorLoaded(res.data));
    })
    .catch((err) => {
      dispatch(failed(err.response));
    });
};
export const cancelFlight =
  (ref: string, func: any, errFunc: any) => (dispatch: any) => {
    dispatch(loading());
    Axios.get(flightCancelUrl + ref, tokenConfig())
      .then((res) => {
        dispatch(flightCancel(res.data));
        func(res.data);
      })
      .catch((err) => {
        dispatch(failed(err.response));
        errFunc();
      });
  };

export const setPage = (page: any) => (dispatch: any) => {
  dispatch(pageSet(page));
};

export const setVendor = (page: any) => (dispatch: any) => {
  dispatch(vendorSelect(page));
};

export const setHistory = (page: any) => (dispatch: any) => {
  dispatch(historySelected(page));
};

export const resetOrder = () => (dispatch: any) => {
  dispatch(orderReset());
};

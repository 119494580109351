/* @ts-ignore */
import UserAvatar from "react-user-avatar";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { formatCurrency } from "../util/Helpers";
import { useDispatch } from "react-redux";
import * as Actions from "../store/actions";
import { Dispatch } from "react";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const Page4 = ({ data, trans }: any) => {
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <div className=" lg:w-[80%] overflow-y-scroll  mx-auto hide-scrollbar  justify-center sticky top-0 flex gap-x-12">
      <div className="w-full lg:w-4/6">
        <div className="w-full   mt-2 rounded-lg p-6 bg-white">
          <div className="flex space-x-3 items-center ">
            <div className="text-5xl text-green-700">
              <IoMdCheckmarkCircleOutline />
            </div>
            <div className="space-y-1">
              <div className="font-semibold text-xl">Success</div>
              <div className="text-sm">
                Your booking request has been accepted. Please await
                confirmation while we process your payment. An email
                notification will be sent to you shortly.
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-1  mt-2 bg-white  rounded-lg"></div>
        <div className="w-full p-1  mt-2 bg-white  rounded-lg"></div>
        <div className="w-full rounded-lg p-6 bg-white">
          <div className="flex justify-between">
            <div className="font-bold flex text-lg lg:text-2xl mb-5">
              Price Details
              <sup>
                <div className="font-light text-sm py-2">
                  All prices are in
                  <span className="font-bold text-slate-400">
                    {trans?.currency}
                  </span>
                </div>
              </sup>
            </div>
            <div
              onClick={() => {
                navigate("/flights");
                dispatch(Actions.setPage(0));
              }}
              className="text-orange-500 md:flex hidden cursor-pointer bg-orange-100 text-[10px] md:text-xs font-light px-2 rounded-xl w-fit h-6  space-x-1 items-center"
            >
              Change Flight
              <BiEditAlt />
            </div>
          </div>

          <div className="py-3 border-b-slate-200 border-b-[1px]">
            {" "}
            <div className="w-full text-xl font-medium">
              {`Ticket for flight ${data.data[0].departurecity} (${data.data[0].departurecode}) - ${data.data[0].arrivalcity} (${data.data[0].arrivalcode})`}
            </div>
          </div>

          <div className="flex justify-between py-1">
            <div>Base Fee</div>
            <div className="font-semibold">
              {formatCurrency(parseFloat(trans.amount), data.data[0].currency)}
            </div>
          </div>
          <div className="flex justify-between py-1">
            <div>Addon Fee</div>
            <div className="font-semibold">
              {formatCurrency(trans.addon, data.data[0].currency)}
            </div>
          </div>
          <div className="flex justify-between py-1">
            <div>Tax Fee</div>
            <div className="font-semibold">
              {formatCurrency(0, data.data[0].currency)}
            </div>
          </div>
          <div className="flex justify-between py-1">
            <div>Total Fare</div>
            <div className="font-semibold">
              {formatCurrency(trans.total, data.data[0].currency)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Page4;

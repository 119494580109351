import { useDispatch, useSelector } from "react-redux";
import { Dispatch, startTransition, useState } from "react";
import * as Actions from "../store/actions";
import { errorMessage, formatCurrency } from "../util/Helpers";
//import { useFlutterwave } from "flutterwave-react-v3";
import { loadStripe } from "@stripe/stripe-js";
import LocalGateway from "./LocalGateway";
import { usePaystackPayment } from "react-paystack";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);
const PaymentForm = ({ data, trans }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  const page = useSelector((state: any) => state.order.page);
  const loading = useSelector((state: any) => state.hold.loading);
  const pconfig = {
    reference: trans.systemRef,
    email: trans?.email,
    amount: parseFloat(trans.total) * 100,
    publicKey: import.meta.env.VITE_PAYSTACK_KEY,
  };
  const initializePayment = usePaystackPayment(pconfig);

  const callGateway = () => {
    initializePayment({ onSuccess: callSuccess });
  };

  const callSuccess = (data: any) => {
    startTransition(() => {
      let payload = {
        systemRef: trans.systemRef,
        processRef: data.transaction,
        vendor: "STACK",
        email: trans.email,
        phoneNumber: trans.phoneNumber,
        currency: "NGN",
        hold: false,
      };
      dispatch(
        Actions.verifyPayment(
          payload,
          () => {
            dispatch(Actions.setPage(page + 1));
          },
          () => {
            errorMessage(
              "Could not complete your transaction, contact adminstrator"
            );
          }
        )
      );
    });
  };

  return (
    <div className="w-full   mt-2 rounded-lg p-6 bg-white">
      <LocalGateway
        isOpen={isOpen}
        data={data}
        trans={trans}
        setIsOpen={setIsOpen}
        stripePromise={stripePromise}
      />
      <button
        disabled={loading}
        onClick={() => {
          if (trans.currency === "NGN") {
            callGateway();
          } else {
            setIsOpen(true);
          }
        }}
        className="bg-orange-500 disabled:bg-orange-200  w-full cursor-pointer font-semibold text-lg rounded-md h-12 flex text-white items-center justify-center px-4 py-4"
      >
        {<div>{`Pay Now ${formatCurrency(trans.total, trans.currency)}`}</div>}
      </button>
    </div>
  );
};
export default PaymentForm;

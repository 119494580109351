import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const BasicPhoneInput = ({
  value,
  label,
  onChange,
  className = "",
  placeholder = "",
  disabled = false,
}: //  icon = null,
any) => {
  // const [value, setValue] = useState();
  return (
    <div className="border-[#000046]  outline-none flex flex-col border-[1px] py-1 h-12 rounded-[4px] w-full text-[#171717] px-2 pl-4 focus:border-b-[#EE723A] focus:border-2">
      <label className="text-xs mb-1 text-slate-500">{label}</label>
      <PhoneInput
        placeholder={placeholder}
        value={value}
        defaultCountry="US"
        disabled={disabled}
        onChange={(value) => {
          onChange(value);
        }}
        style={{ background: "transparent" }}
        //  value={value}
        //  onChange={onChange}
        className={
          "bg-transparent h-full outline-none  placeholder:text-gray-600 " +
          className
        }
      />

      {/* @ts-ignore */}
      <style jsx={true}>{`
        .PhoneInputInput {
          flex: 1;
          min-width: 0;
          background: transparent;
          outline: none;
          margin-left: 3px;
          border-left: 1px solid #33373e;
          padding-left: 6px;
        }
      `}</style>
    </div>
  );
};
export default BasicPhoneInput;

import { createSlice } from "@reduxjs/toolkit";

interface MiscState {
  loading: boolean;
  deals: any;
  destination: any;
  failed: any;
  cheapFlights: any;
  seconds: any;
  isActive: any;
}

const initialState: MiscState = {
  loading: false,
  failed: null,
  deals: null,
  destination: null,
  cheapFlights: null,
  seconds: 1200,
  isActive: false,
};

const miscSlice = createSlice({
  name: "misc",
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true;
      state.failed = null;
    },
    failed: (state, action) => {
      state.loading = false;
      state.failed = action.payload;
    },
    dealsLoaded: (state, action) => {
      state.loading = false;
      state.deals = action.payload;
    },
    destinationLoaded: (state, action) => {
      state.loading = false;
      state.destination = action.payload.data;
    },
    cheapFlightLoaded: (state, action) => {
      state.loading = false;
      state.cheapFlights = action.payload.data;
    },
    secondsSet: (state) => {
      state.seconds = state.seconds - 1;
    },
    secondReset: (state) => {
      state.seconds = 1200;
      state.isActive = false;
    },
    isActiveSet: (state) => {
      state.isActive = !state.isActive;
    },
  },
});

export const {
  failed,
  loading,
  isActiveSet,
  secondsSet,
  dealsLoaded,
  secondReset,
  destinationLoaded,
  cheapFlightLoaded,
} = miscSlice.actions;

export default miscSlice.reducer;
